import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import SettingList from './settingList';
import CommissionsList from './commissionsList';
import Context from "../context";
import ActionButtons from '../../components/actionButtons';
import CertificateTypes from "../../certificateTypes";
import JobCertificateTypes from '../../jobCertificateTypes';
import { deleteSubSkills } from '../service';

export default function CardView({sub_skill, key}) {
    const context = React.useContext(Context);

    return (
        <>
            <Box sx={{ bgcolor: 'background.paper' }} className="pt-1 pb-1">
                <Box sx={{ my: 3, mx: 2 }}>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography gutterBottom variant="h6" component="div">
                                {sub_skill.sub_skill_name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ActionButtons
                                is_remove_add_btn = {false}
                                editFunction = {() => context.dispatch({
                                    type: 'IS_SHOW_EDIT_SUB_SKILLS', 
                                    is_show_edit_sub_skills: true,
                                    selected_sub_skills: sub_skill
                                })}
                                deleteFunction = {() => deleteSubSkills(sub_skill, context.dispatch)}
                            />
                        </Grid>
                    </Grid>
                    <SettingList
                        hourly_rate = {sub_skill.sub_skill_hourly_rate}
                        company_commission = {sub_skill.sub_skill_company_commission}
                    />
                </Box>
                {/* <Divider variant="middle" /> */}
                <Box sx={{ m: 2 }}>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography gutterBottom variant="body1">
                                <b>COMMISSIONS</b>
                            </Typography>
                        </Grid>

                        <Grid item>
                            <IconButton 
                                aria-label="edit"
                                onClick = {() => context.dispatch({
                                    type: 'IS_SHOW_MATERIAL_COMMISSIONS', 
                                    is_show_material_commissions: true,
                                    selected_sub_skills: sub_skill
                                })}
                            >
                                <DriveFileRenameOutlineIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <CommissionsList
                        material_commissions = {sub_skill.material_commissions}
                    />
                </Box>
                <Box sx={{ m: 2 }}>
                    <CertificateTypes
                        skill= {{
                            skill_id: sub_skill.sub_skill_id,
                            skill_name: sub_skill.sub_skill_name
                        }}
                        key = {key}
                        type = {1}
                    />
                </Box>
                <Box sx={{ m: 2 }}>
                    <JobCertificateTypes
                        sub_skill= {{
                            sub_skill_id: sub_skill.sub_skill_id,
                            sub_skill_name: sub_skill.sub_skill_name
                        }}
                        key = {key}
                    />
                </Box>
            </Box>
        </>
    );
}