import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { Stack, TextField, Box } from '@mui/material';
import { editSubSkill } from '../service';
import { theme } from '../../../theme';
import BtnSpinner from '../../../components/btnSpinner';
import Context from '../context';
import ModalTemplateWithForm from '../../../components/modal/modalTemplateWithForm';
import MuiIconPicker from '../../../components/muiIconPicker';

const EditSkills = () => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);
    const [selectedCustomerWebAppIcon, setSelectedCustomerWebAppIcon] = useState(null);
    const [selectedCustomerMobileAppIcon, setSelectedCustomerMobileAppIcon] = useState(null);

    const handleCustomerSkillIconSelect = (iconData) => {
        setSelectedCustomerWebAppIcon(iconData.name);
        setSelectedCustomerMobileAppIcon(iconData.code);
    };

    const closeEditSkillModal = () => {
        context.dispatch({
            type: 'IS_SHOW_EDIT_SKILLS', 
            is_show_edit_skills: false,
            selected_skills: {}
        });
        setSelectedCustomerWebAppIcon(null);
        setSelectedCustomerMobileAppIcon(null);
    };

    return (
        <>
            <ModalTemplateWithForm
                show={context.state.is_show_edit_skills} 
                closeFun={closeEditSkillModal}
                size = {null}
                centered = {false}
                fullscreen = {false}
                dialogClassName = {null}
                title = {`Edit ${context.state.selected_skills.skill_name}`}
                body = {
                    <Container>
                        {context.state.error !== null ? (
                            <Stack sx={{ width: '100%' }} spacing={2} className="mb-3">
                                <Alert severity="error">{context.state.error}</Alert>
                            </Stack>
                        ) : null}

                        <label style={{ color: 'black' }}>NAME</label>
                        <TextField
                            placeholder="Service category name"
                            defaultValue={context.state.selected_skills.skill_name}
                            fullWidth
                            size="small"
                            name="skill_name"
                            InputProps={{
                                style: { backgroundColor: '#fff' },
                            }}
                            // {...register('skill_name', { required: false })}
                        />

                        <label style={{ color: 'black' }}>ADD ICONS FOR CUSTOMER APPS</label>
                        <MuiIconPicker onIconSelect={(iconData) => handleCustomerSkillIconSelect(iconData)} />
                        
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2} // Adds space between the TextFields
                        >
                            <TextField
                                placeholder="Web app icon"
                                defaultValue={context.state.selected_skills.skill_web_app_icon}
                                fullWidth
                                size="small"
                                name="skill_customer_web_app_icon"
                                value={selectedCustomerWebAppIcon}
                                InputProps={{
                                    style: { backgroundColor: '#fff' },
                                }}
                                // {...register('skill_customer_web_app_icon', { required: false })}
                            />

                            <TextField
                                placeholder="Mobile app icon"
                                defaultValue={context.state.selected_skills.skill_mobile_app_icon}
                                fullWidth
                                size="small"
                                name="skill_customer_mobile_app_icon"
                                value={selectedCustomerMobileAppIcon}
                                InputProps={{
                                    style: { backgroundColor: '#fff' },
                                }}
                                // {...register('skill_customer_mobile_app_icon', { required: false })}
                            />
                        </Box>
                    </Container>
                }
                footer = {
                    <>
                        <Button
                            variant={theme.close_btn}
                            onClick={closeEditSkillModal}
                            size="sm"
                            disabled={context.state.btn_loading}
                        >
                            {context.state.btn_loading ? <BtnSpinner /> : 'CLOSE'}
                        </Button>
                        <Button
                            variant={theme.action_btn}
                            type="submit"
                            size="sm"
                            disabled={context.state.btn_loading}
                        >
                            {context.state.btn_loading ? <BtnSpinner /> : 'SAVE'}
                        </Button>
                    </>
                }
                onSubmit = {(event) => editSubSkill(event, context.state.selected_skills.skill_id, context.dispatch, reset)}
            />
        </>
    );
};

export default EditSkills;