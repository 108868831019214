/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import Html from "./html";
import { Provider } from './provider';

const MuiIconPicker = ({ onIconSelect, app }) => {
    return (
        <>
            <Provider>
                <Html onIconSelect={onIconSelect} app={app} />
            </Provider>
        </>
    );
}

export default MuiIconPicker;