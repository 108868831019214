import React, { useState, useEffect, useRef, useContext } from "react";
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Context } from './provider';
import { muiIconCodes } from '../../constants/muiIconCodes';

const Html = ({ onIconSelect, app }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const popoverRef = useRef(null);
    const context = useContext(Context);

    // Utility function to convert camelCase to snake_case
    const toSnakeCase = (str) => {
        return str
            .replace(/([A-Z])/g, "_$1") // Add an underscore before each uppercase letter
            .toLowerCase() // Convert the entire string to lowercase
            .slice(1); // Remove the leading underscore
    };

    const handleIconSelect = (iconName) => {
        const newIconName = toSnakeCase(iconName);
        const iconCode = muiIconCodes[newIconName];

        const iconData = {
            name: newIconName,
            code: iconCode
        };

        onIconSelect(iconData);
        
        setPopoverOpen(false);
    };

    const handleOpen = () => {
        setPopoverOpen(true);
    };

    const handleNextPage = () => {
        if ((context.state.current_page + 1) * context.state.icons_per_page < context.state.icon_count) {
            context.dispatch({type: 'UPDATE_CURRENT_PAGE', current_page: context.state.current_page + 1});
        }
    };
    
    const handlePreviousPage = () => {
        if (context.state.current_page > 0) {
            context.dispatch({type: 'UPDATE_CURRENT_PAGE', current_page: context.state.current_page - 1});
        }
    };

    // Close popover on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setPopoverOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return(
        <>
            <IconButton onClick={handleOpen}>
                <AddCircleIcon />
            </IconButton>

            {popoverOpen && (
                <div 
                    ref={popoverRef}
                        style={{
                        position: 'absolute',
                        top: '40%',
                        left: '56%',
                        width: '300px',
                        background: 'white',
                        border: '1px solid #ccc',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        padding: '16px',
                        zIndex: 1000}}
                >
                    <Typography variant="h6" gutterBottom>
                        Select an Icon
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search icons..."
                        value={context.state.search_value}
                        onChange={(e) => {
                            context.dispatch({type: 'SET_SEARCH_VALUE', search_value: e.target.value});
                            context.dispatch({type: 'UPDATE_CURRENT_PAGE', current_page: 0}); // Reset to first page on search
                        }}
                        margin="normal"
                        size="small"
                    />
                    <Grid container spacing={2}>
                        {context.state.icon_list.map((iconName, index) => {
                            const IconComponent = context.MuiIcons[iconName];
                            return (
                                <Grid item xs={3} key={index}>
                                    <Tooltip title={iconName} arrow>
                                        <Button
                                            onClick={() => handleIconSelect(iconName)}
                                            style={{ textTransform: "none" }}
                                        >
                                            <IconComponent />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 16,
                        }}
                    >
                        <Button
                            disabled={context.state.current_page === 0}
                            onClick={handlePreviousPage}
                            variant="outlined"
                            size="small"
                        >
                            Previous
                        </Button>
                        <Button
                            disabled={
                                (context.state.current_page + 1) * context.state.icons_per_page >= context.state.icon_count
                            }
                            onClick={handleNextPage}
                            variant="outlined"
                            size="small"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default Html;