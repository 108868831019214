import { getUserInfo } from "../../storage";

const userInfo = getUserInfo();

const serviceCategory = [];
if (userInfo?.admin_role_id == 1) {
    serviceCategory.push({
        heder: "SERVICE CATEGORIES",
        heder_icon: "move_down",
        is_navigation: true,
        list: [
            {
                name: "Service Categories",
                icon: "move_down",
                to: "/skills",
                iconName: "Service Categories"
            }
        ]
    });
}

export const data = [
    {
        heder: "",
        heder_icon: "",
        is_navigation: true,
        list: [
            {
                name: "Dashboard",
                icon: "dashboard",
                to: "/dashboard",
                iconName: "Dashboard"
            }
        ]
    },
    {
        heder: "TRADESPERSONS",
        heder_icon: "engineering",
        is_navigation: true,
        list: [
            {
                name: "All",
                icon: "table_view",
                to: "/ftm/list",
                iconName: "All Tradespersons"
            },
            {
                name: "Applications",
                icon: "receipt_long",
                to: "/ftm/pending/application",
                iconName: "Tradesperson Applications"
            },
            {
                name: "Activities",
                icon: "assignment",
                to: "/ftm/all/logs",
                iconName: "Tradesperson Activities"
            },
            {
                name: "Rejected Jobs",
                icon: "thumb_down",
                to: "/ftm/rejected/jobs",
                iconName: "Tradesperson Rejected Jobs"
            },
            {
                name: "Holidays",
                icon: "event",
                to: "/ftm/holidays",
                iconName: "Tradesperson Holidays"
            },
            {
                name: "Expired Documents",
                icon: "snooze",
                to: "/expired/documents",
                iconName: "Expired Documents"
            }
        ]
    },
    {
        heder: "CUSTOMERS",
        heder_icon: "supervisor_account",
        is_navigation: true,
        list: [
            {
                name: "All",
                icon: "recent_actors",
                to: "/customers/all",
                iconName: "All Customers"
            }
        ]
    },
    {
        heder: "JOBS",
        heder_icon: "work",
        is_navigation: false,
        list: [
            {
                name: "All",
                icon: "work",
                to: "/jobs/all",
                iconName: "All Jobs"
            },
            {
                name: "Ongoing",
                icon: "call_missed_outgoing",
                to: "/jobs/ongoing",
                iconName: "Ongoing Jobs"
            },
            {
                name: "Completed - Paid",
                icon: "credit_score",
                to: "/jobs/complied/paid",
                iconName: "Completed Paid Jobs"
            },
            {
                name: "Completed - Not Paid",
                icon: "credit_card_off",
                to: "/jobs/complied/notpaid",
                iconName: "Completed Not Paid Jobs"
            },
            {
                name: "Completed - In Dispute",
                icon: "check_circle",
                to: "/jobs/complied/indispute",
                iconName: "Completed Jobs In Dispute"
            },
            {
                name: "Not Attended",
                icon: "do_not_step",
                to: "/jobs/not/attended",
                iconName: "Not Attended Jobs"
            },
            {
                name: "Cancelled Jobs",
                icon: "cancel",
                to: "/jobs/cancel",
                iconName: "Cancelled Jobs"
            },
            {
                name: "Disputes",
                icon: "backspace",
                to: "/messages/disputes/inbox",
                iconName: "Disputes"
            },
            {
                name: "Payments",
                icon: "payments",
                to: "/all/payments",
                iconName: "Payments"
            },
            {
                name: "Remedial Jobs",
                icon: "published_with_changes",
                to: "/jobs/remedial",
                iconName: "Remedial Jobs"
            },
            {
                name: "Direct Chat",
                icon: "forum",
                to: "/messages/jobs/inbox",
                iconName: "Direct Chat"
            }
        ]
    },
    ...serviceCategory,
    {
        heder: "SUMMARY",
        heder_icon: "summarize",
        is_navigation: true,
        list: [
            {
                name: "Summary",
                icon: "fact_check",
                to: "/payments/summary",
                iconName: "Summary"
            }
        ]
    }
]; 