import React, {useContext} from "react";

import Template from "../../components/template";
import JobMap from "./components/jobMap";
import NavBtn from "./components/navBtn";
import Details from "./components/details";
import JobType from "./components/jobType";
import JobProfileImage from "./components/jobProfileImage";
import Ftm from "./components/ftm";
import JobValues from "./components/jobValues";
import ExtraPayments from "./components/extraPayments";
import Payments from "./components/payments";
import InvoiceOffcanvas from "../components/invoice/invoiceOffcanvas";
import Context from "./context";
import JobsheetOffcanvas from "../components/jobsheet/jobsheetOffcanvas";
import JobImages from "./components/jobImages";
import Chat from "../components/chat";
import FtmRequest from "../ftmRequest";
import JobStatusChangeLogs from "./components/jobStatusChangeLogs";
import Notification from "./components/notification";
import JobCertificates from "./components/jobCertificates";

const Html = () => {
    const context = useContext(Context);

    return (
        <Template>
            <div className="container-fluid px-2 px-md-4">
                <JobMap/>
                <div className="card card-body mx-3 mx-md-4 mt-n6">
                    <Notification/>
                    <div className="row gx-4 mb-2">
                        <JobProfileImage/>
                        <JobType/>
                        <NavBtn/>
                    </div>
                    <div className="row">
                        <div className="row">
                            <Details/>
                            {context.state?.ftms && <Ftm/>}
                            <JobValues/>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <ExtraPayments/>
                    </div>

                    <div className="row mb-4">
                        <Payments/>
                    </div>

                    <div className="row mb-4">
                        <JobImages
                            images= {context.state.images}
                        />
                    </div>

                    <div className="row mb-4">
                        <JobCertificates
                            job_certificates= {context.state.job_details.job_certificates}
                        />
                    </div>
                </div>
            </div>

            <InvoiceOffcanvas
                job_id = {context.job_id}
                show = {context.state.is_show_invoice}
                handleClose = {() => context.dispatch({
                    type: 'IS_SHOW_INVOICE',
                    is_show_invoice: false
                })}
            />

            <JobsheetOffcanvas
                job_id = {context.job_id}
                show = {context.state.is_show_jobsheet}
                handleClose = {() => context.dispatch({
                    type: 'IS_SHOW_JOBSHEET',
                    is_show_jobsheet: false
                })}
            />

            <Chat
                job_id = {context.job_id}
                job_firebase_document_id = {context.state.job_details.job_firebase_document_id}
                show = {context.state.is_show_chat}
                handleClose = {() => context.dispatch({
                    type: 'IS_SHOW_CHAT',
                    is_show_chat: false
                })}
            />

            <FtmRequest
                job_id = {context.job_id}
                show = {context.state.is_show_ftm_request}
                handleClose = {() => context.dispatch({
                    type: 'IS_SHOW_FTM_REQUEST',
                    is_show_ftm_request: false
                })}
            />

            <JobStatusChangeLogs/>
        </Template>
    );
}

export default Html;