import React, { createContext, useState, useEffect, useReducer } from 'react';
import * as MuiIcons from "@mui/icons-material";

import { initialState } from "./initialState";
import Reducer from "./reducer";

const Context = createContext();

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer,initialState);

  useEffect(() => {
    const fetchIconList = async () => {
      const iconCount = Object.keys(MuiIcons).length;
      // Paginate icons
      const startIndex = state.current_page * state.icons_per_page;
      const iconList = Object.keys(MuiIcons).filter(
          (icon) =>
          !icon.match(/(Outlined|Rounded|Sharp|TwoTone)/) &&
          icon.toLowerCase().includes(state.search_value.toLowerCase())
      ).slice(
          startIndex,
          startIndex + state.icons_per_page
      );

      dispatch({
        type: 'ICON_LIST', 
        icon_list: iconList,
        icon_count: iconCount
      });
    }

    fetchIconList();
  }, [state.current_page, state.search_value, state.icons_per_page]);

  return (
    <Context.Provider value={{state, dispatch, MuiIcons}}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };