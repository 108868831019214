export const muiIconCodes = {
    ten_mp: "0xe001",
    eleven_mp: "0xe002",
    twelve_mp: "0xe003",
    thirteen_mp: "0xe004",
    fourteen_mp: "0xe005",
    fifteen_mp: "0xe006",
    sixteen_mp: "0xe007",
    seventeen_mp: "0xe008",
    eighteen_up_rating: "0xf0784",
    eighteen_mp: "0xe009",
    nineteen_mp: "0xe00a",
    one_k: "0xe00b",
    one_k_plus: "0xe00c",
    twenty_one_mp: "0xe00f",
    twenty_two_mp: "0xe010",
    twenty_three_mp: "0xe011",
    twenty_four_mp: "0xe012",
    two_k: "0xe013",
    two_k_plus: "0xe014",
    two_mp: "0xe015",
    thirty_fps: "0xe016",
    thirty_fps_select: "0xe017",
    three_g_mobiledata: "0xe01a",
    three_k: "0xe01b",
    three_k_plus: "0xe01c",
    three_mp: "0xe01d",
    three_p: "0xe01e",
    four_g_mobiledata: "0xe01f",
    four_g_plus_mobiledata: "0xe020",
    four_k: "0xe021",
    four_k_plus: "0xe022",
    four_mp: "0xe023",
    five_g: "0xe024",
    five_k: "0xe025",
    five_k_plus: "0xe026",
    five_mp: "0xe027",
    sixty_fps: "0xe028",
    sixty_fps_select: "0xe029",
    six_k: "0xe02b",
    six_k_plus: "0xe02c",
    six_mp: "0xe02d",
    seven_k: "0xe02e",
    seven_k_plus: "0xe02f",
    seven_mp: "0xe030",
    eight_k: "0xe031",
    eight_k_plus: "0xe032",
    eight_mp: "0xe033",
    nine_k: "0xe034",
    nine_k_plus: "0xe035",
    nine_mp: "0xe036",
    abc: "0xf04b6",
    ac_unit: "0xe037",
    access_alarm: "0xe038",
    access_alarms: "0xe039",
    access_time: "0xe03a",
    access_time_filled: "0xe03b",
    accessibility: "0xe03c",
    accessibility_new: "0xe03d",
    accessible: "0xe03e",
    accessible_forward: "0xe03f",
    account_balance: "0xe040",
    account_balance_wallet: "0xe041",
    account_box: "0xe042",
    account_circle: "0xe043",
    account_tree: "0xe044",
    ad_units: "0xe045",
    adb: "0xe046",
    add: "0xe047",
    add_a_photo: "0xe048",
    add_alarm: "0xe049",
    add_alert: "0xe04a",
    add_box: "0xe04b",
    add_business: "0xe04c",
    add_card: "0xf04b7",
    add_circle: "0xe04f",
    add_circle_outline: "0xe050",
    add_comment: "0xe051",
    add_home: "0xf0785",
    add_home_work: "0xf0786",
    add_ic_call: "0xe052",
    add_link: "0xe053",
    add_location: "0xe054",
    add_location_alt: "0xe055",
    add_moderator: "0xe056",
    add_photo_alternate: "0xe057",
    add_reaction: "0xe058",
    add_road: "0xe059",
    add_shopping_cart: "0xe05a",
    add_task: "0xe05b",
    add_to_drive: "0xe05c",
    add_to_home_screen: "0xe05d",
    add_to_photos: "0xe05e",
    add_to_queue: "0xe05f",
    addchart: "0xe060",
    adf_scanner: "0xf04b8",
    adjust: "0xe061",
    admin_panel_settings: "0xe062",
    ads_click: "0xf04ba",
    agriculture: "0xe063",
    air: "0xe064",
    airline_seat_flat: "0xe065",
    airline_seat_flat_angled: "0xe066",
    airline_seat_individual_suite: "0xe067",
    airline_seat_legroom_extra: "0xe068",
    airline_seat_legroom_normal: "0xe069",
    airline_seat_legroom_reduced: "0xe06a",
    airline_seat_recline_extra: "0xe06b",
    airline_seat_recline_normal: "0xe06c",
    airline_stops: "0xf04bb",
    airlines: "0xf04bc",
    airplane_ticket: "0xe06d",
    airplanemode_active: "0xe06e",
    airplanemode_inactive: "0xe06f",
    airplay: "0xe070",
    airport_shuttle: "0xe071",
    alarm: "0xe072",
    alarm_add: "0xe073",
    alarm_off: "0xe074",
    alarm_on: "0xe075",
    album: "0xe076",
    align_horizontal_center: "0xe077",
    align_horizontal_left: "0xe078",
    align_horizontal_right: "0xe079",
    align_vertical_bottom: "0xe07a",
    align_vertical_center: "0xe07b",
    align_vertical_top: "0xe07c",
    all_inbox: "0xe07d",
    all_inclusive: "0xe07e",
    all_out: "0xe07f",
    alt_route: "0xe080",
    alternate_email: "0xe081",
    analytics: "0xe083",
    anchor: "0xe084",
    android: "0xe085",
    animation: "0xe086",
    announcement: "0xe087",
    aod: "0xe088",
    apartment: "0xe089",
    api: "0xe08a",
    app_blocking: "0xe08b",
    app_registration: "0xe08c",
    app_settings_alt: "0xe08d",
    app_shortcut: "0xf04bd",
    apple: "0xf04be",
    approval: "0xe08e",
    apps: "0xe08f",
    apps_outage: "0xf04bf",
    architecture: "0xe090",
    archive: "0xe091",
    arrow_back: "0xe092",
    arrow_back_ios: "0xe093",
    arrow_back_ios_new: "0xe094",
    arrow_circle_down: "0xe095",
    arrow_circle_left: "0xf04c1",
    arrow_circle_right: "0xf04c2",
    arrow_circle_up: "0xe096",
    arrow_downward: "0xe097",
    arrow_drop_down: "0xe098",
    arrow_drop_down_circle: "0xe099",
    arrow_drop_up: "0xe09a",
    arrow_forward: "0xe09b",
    arrow_forward_ios: "0xe09c",
    arrow_left: "0xe09d",
    arrow_outward: "0xf0852",
    arrow_right: "0xe09e",
    arrow_right_alt: "0xe09f",
    arrow_upward: "0xe0a0",
    art_track: "0xe0a1",
    article: "0xe0a2",
    aspect_ratio: "0xe0a3",
    assessment: "0xe0a4",
    assignment: "0xe0a5",
    assignment_ind: "0xe0a6",
    assignment_late: "0xe0a7",
    assignment_return: "0xe0a8",
    assignment_returned: "0xe0a9",
    assignment_turned_in: "0xe0aa",
    assist_walker: "0xf0854",
    assistant: "0xe0ab",
    assistant_direction: "0xe0ac",
    assistant_photo: "0xe0ae",
    assured_workload: "0xf04c3",
    atm: "0xe0af",
    attach_email: "0xe0b0",
    attach_file: "0xe0b1",
    attach_money: "0xe0b2",
    attachment: "0xe0b3",
    attractions: "0xe0b4",
    attribution: "0xe0b5",
    audio_file: "0xf04c4",
    audiotrack: "0xe0b6",
    auto_awesome: "0xe0b7",
    auto_awesome_mosaic: "0xe0b8",
    auto_awesome_motion: "0xe0b9",
    auto_delete: "0xe0ba",
    auto_fix_high: "0xe0bb",
    auto_fix_normal: "0xe0bc",
    auto_fix_off: "0xe0bd",
    auto_graph: "0xe0be",
    auto_mode: "0xf0787",
    auto_stories: "0xe0bf",
    autofps_select: "0xe0c0",
    autorenew: "0xe0c1",
    av_timer: "0xe0c2",
    baby_changing_station: "0xe0c3",
    back_hand: "0xf04c5",
    backpack: "0xe0c4",
    backspace: "0xe0c5",
    backup: "0xe0c6",
    backup_table: "0xe0c7",
    badge: "0xe0c8",
    bakery_dining: "0xe0c9",
    balance: "0xf04c6",
    balcony: "0xe0ca",
    ballot: "0xe0cb",
    bar_chart: "0xe0cc",
    batch_prediction: "0xe0cd",
    bathroom: "0xe0ce",
    bathtub: "0xe0cf",
    battery_alert: "0xe0d0",
    battery_charging_full: "0xe0d1",
    battery_full: "0xe0d2",
    battery_saver: "0xe0d3",
    battery_std: "0xe0d4",
    battery_unknown: "0xe0d5",
    beach_access: "0xe0d6",
    bed: "0xe0d7",
    bedroom_baby: "0xe0d8",
    bedroom_child: "0xe0d9",
    bedroom_parent: "0xe0da",
    bedtime: "0xe0db",
    bedtime_off: "0xf04c7",
    beenhere: "0xe0dc",
    bento: "0xe0dd",
    bike_scooter: "0xe0de",
    biotech: "0xe0df",
    blender: "0xe0e0",
    blind: "0xf0856",
    blinds: "0xf078f",
    blinds_closed: "0xf0790",
    block: "0xe0e1",
    bloodtype: "0xe0e3",
    bluetooth: "0xe0e4",
    bluetooth_audio: "0xe0e5",
    bluetooth_connected: "0xe0e6",
    bluetooth_disabled: "0xe0e7",
    bluetooth_drive: "0xe0e8",
    bluetooth_searching: "0xe0e9",
    blur_circular: "0xe0ea",
    blur_linear: "0xe0eb",
    blur_off: "0xe0ec",
    blur_on: "0xe0ed",
    bolt: "0xe0ee",
    book: "0xe0ef",
    book_online: "0xe0f0",
    bookmark: "0xe0f1",
    bookmark_add: "0xe0f2",
    bookmark_added: "0xe0f3",
    bookmark_border: "0xe0f4",
    bookmark_remove: "0xe0f5",
    bookmarks: "0xe0f6",
    border_all: "0xe0f7",
    border_bottom: "0xe0f8",
    border_clear: "0xe0f9",
    border_color: "0xe0fa",
    border_horizontal: "0xe0fb",
    border_inner: "0xe0fc",
    border_left: "0xe0fd",
    border_outer: "0xe0fe",
    border_right: "0xe0ff",
    border_style: "0xe100",
    border_top: "0xe101",
    border_vertical: "0xe102",
    boy: "0xf04c8",
    branding_watermark: "0xe103",
    breakfast_dining: "0xe104",
    brightness_auto: "0xe10c",
    brightness_high: "0xe10d",
    brightness_low: "0xe10e",
    brightness_medium: "0xe10f",
    broadcast_on_home: "0xf0791",
    broadcast_on_personal: "0xf0792",
    broken_image: "0xe110",
    browse_gallery: "0xf06ba",
    browser_not_supported: "0xe111",
    browser_updated: "0xf04c9",
    brunch_dining: "0xe112",
    brush: "0xe113",
    bubble_chart: "0xe114",
    bug_report: "0xe115",
    build: "0xe116",
    build_circle: "0xe117",
    bungalow: "0xe118",
    burst_mode: "0xe119",
    bus_alert: "0xe11a",
    business: "0xe11b",
    business_center: "0xe11c",
    cabin: "0xe11d",
    cable: "0xe11e",
    cached: "0xe11f",
    cake: "0xe120",
    calculate: "0xe121",
    calendar_month: "0xf06bb",
    calendar_today: "0xe122",
    calendar_view_day: "0xe123",
    calendar_view_month: "0xe124",
    calendar_view_week: "0xe125",
    call: "0xe126",
    call_end: "0xe127",
    call_made: "0xe128",
    call_merge: "0xe129",
    call_missed: "0xe12a",
    call_missed_outgoing: "0xe12b",
    call_received: "0xe12c",
    call_split: "0xe12d",
    call_to_action: "0xe12e",
    camera: "0xe12f",
    camera_alt: "0xe130",
    camera_enhance: "0xe131",
    camera_front: "0xe132",
    camera_indoor: "0xe133",
    camera_outdoor: "0xe134",
    camera_rear: "0xe135",
    camera_roll: "0xe136",
    cameraswitch: "0xe137",
    campaign: "0xe138",
    cancel: "0xe139",
    cancel_presentation: "0xe13a",
    cancel_schedule_send: "0xe13b",
    candlestick_chart: "0xf04ca",
    car_crash: "0xf0793",
    car_rental: "0xe13c",
    car_repair: "0xe13d",
    card_giftcard: "0xe13e",
    card_membership: "0xe13f",
    card_travel: "0xe140",
    carpenter: "0xe141",
    cases: "0xe142",
    casino: "0xe143",
    cast: "0xe144",
    cast_connected: "0xe145",
    cast_for_education: "0xe146",
    castle: "0xf04cb",
    catching_pokemon: "0xe147",
    category: "0xe148",
    celebration: "0xe149",
    cell_tower: "0xf04cc",
    cell_wifi: "0xe14a",
    center_focus_strong: "0xe14b",
    center_focus_weak: "0xe14c",
    chair: "0xe14d",
    chair_alt: "0xe14e",
    chalet: "0xe14f",
    change_circle: "0xe150",
    change_history: "0xe151",
    charging_station: "0xe152",
    chat: "0xe153",
    chat_bubble: "0xe154",
    chat_bubble_outline: "0xe155",
    check: "0xe156",
    check_box: "0xe157",
    check_box_outline_blank: "0xe158",
    check_circle: "0xe159",
    check_circle_outline: "0xe15a",
    checklist: "0xe15b",
    checklist_rtl: "0xe15c",
    checkroom: "0xe15d",
    chevron_left: "0xe15e",
    chevron_right: "0xe15f",
    child_care: "0xe160",
    child_friendly: "0xe161",
    chrome_reader_mode: "0xe162",
    church: "0xf04cd",
    circle: "0xe163",
    circle_notifications: "0xe164",
    clean_hands: "0xe166",
    cleaning_services: "0xe167",
    clear: "0xe168",
    clear_all: "0xe169",
    close: "0xe16a",
    close_fullscreen: "0xe16b",
    closed_caption: "0xe16c",
    closed_caption_disabled: "0xe16d",
    closed_caption_off: "0xe16e",
    cloud: "0xe16f",
    cloud_circle: "0xe170",
    cloud_done: "0xe171",
    cloud_download: "0xe172",
    cloud_off: "0xe173",
    cloud_queue: "0xe174",
    cloud_sync: "0xf04ce",
    cloud_upload: "0xe175",
    co2: "0xf04d0",
    co_present: "0xf04d1",
    code: "0xe176",
    code_off: "0xe177",
    coffee: "0xe178",
    coffee_maker: "0xe179",
    collections: "0xe17a",
    collections_bookmark: "0xe17b",
    color_lens: "0xe17c",
    colorize: "0xe17d",
    comment: "0xe17e",
    comment_bank: "0xe17f",
    comments_disabled: "0xf04d2",
    commit: "0xf04d3",
    commute: "0xe180",
    compare: "0xe181",
    compare_arrows: "0xe182",
    compass_calibration: "0xe183",
    compress: "0xe184",
    computer: "0xe185",
    confirmation_number: "0xe186",
    connect_without_contact: "0xe187",
    connected_tv: "0xe188",
    connecting_airports: "0xf04d5",
    construction: "0xe189",
    contact_emergency: "0xf0857",
    contact_mail: "0xe18a",
    contact_page: "0xe18b",
    contact_phone: "0xe18c",
    contact_support: "0xe18d",
    contactless: "0xe18e",
    contacts: "0xe18f",
    content_copy: "0xe190",
    content_cut: "0xe191",
    content_paste: "0xe192",
    content_paste_go: "0xf04d6",
    content_paste_off: "0xe193",
    content_paste_search: "0xf04d7",
    contrast: "0xf04d8",
    control_camera: "0xe194",
    control_point: "0xe195",
    control_point_duplicate: "0xe196",
    cookie: "0xf04d9",
    copy_all: "0xe197",
    copyright: "0xe198",
    coronavirus: "0xe199",
    corporate_fare: "0xe19a",
    cottage: "0xe19b",
    countertops: "0xe19c",
    create: "0xe19d",
    create_new_folder: "0xe19e",
    credit_card: "0xe19f",
    credit_card_off: "0xe1a0",
    credit_score: "0xe1a1",
    crib: "0xe1a2",
    crisis_alert: "0xf0794",
    crop: "0xe1a3",
    crop_din: "0xe1a8",
    crop_free: "0xe1a9",
    crop_landscape: "0xe1aa",
    crop_original: "0xe1ab",
    crop_portrait: "0xe1ac",
    crop_rotate: "0xe1ad",
    crop_square: "0xe1ae",
    css: "0xf04db",
    currency_bitcoin: "0xf06bc",
    currency_exchange: "0xf04dc",
    currency_franc: "0xf04dd",
    currency_lira: "0xf04de",
    currency_pound: "0xf04df",
    currency_ruble: "0xf04e0",
    currency_rupee: "0xf04e1",
    currency_yen: "0xf04e2",
    currency_yuan: "0xf04e3",
    curtains: "0xf0795",
    curtains_closed: "0xf0796",
    cyclone: "0xf0797",
    dangerous: "0xe1af",
    dark_mode: "0xe1b0",
    dashboard: "0xe1b1",
    dashboard_customize: "0xe1b2",
    data_array: "0xf04e4",
    data_object: "0xf04e6",
    data_saver_off: "0xe1b3",
    data_saver_on: "0xe1b4",
    data_thresholding: "0xf04e7",
    data_usage: "0xe1b5",
    dataset: "0xf0798",
    dataset_linked: "0xf0799",
    date_range: "0xe1b6",
    deblur: "0xf04e8",
    deck: "0xe1b7",
    dehaze: "0xe1b8",
    delete: "0xe1b9",
    delete_forever: "0xe1ba",
    delete_outline: "0xe1bb",
    delete_sweep: "0xe1bc",
    delivery_dining: "0xe1bd",
    density_large: "0xf04e9",
    density_medium: "0xf04ea",
    density_small: "0xf04eb",
    departure_board: "0xe1be",
    description: "0xe1bf",
    deselect: "0xf04ec",
    design_services: "0xe1c0",
    desk: "0xf079a",
    desktop_access_disabled: "0xe1c1",
    desktop_mac: "0xe1c2",
    desktop_windows: "0xe1c3",
    details: "0xe1c4",
    developer_board: "0xe1c5",
    developer_board_off: "0xe1c6",
    developer_mode: "0xe1c7",
    device_hub: "0xe1c8",
    device_thermostat: "0xe1c9",
    device_unknown: "0xe1ca",
    devices: "0xe1cb",
    devices_fold: "0xf079b",
    devices_other: "0xe1cc",
    dialer_sip: "0xe1cd",
    dialpad: "0xe1ce",
    diamond: "0xf04ed",
    difference: "0xf04ee",
    dining: "0xe1cf",
    dinner_dining: "0xe1d0",
    directions: "0xe1d1",
    directions_bike: "0xe1d2",
    directions_boat: "0xe1d3",
    directions_boat_filled: "0xe1d4",
    directions_bus: "0xe1d5",
    directions_bus_filled: "0xe1d6",
    directions_car: "0xe1d7",
    directions_car_filled: "0xe1d8",
    directions_off: "0xe1d9",
    directions_railway: "0xe1da",
    directions_railway_filled: "0xe1db",
    directions_run: "0xe1dc",
    directions_subway: "0xe1dd",
    directions_subway_filled: "0xe1de",
    directions_transit: "0xe1df",
    directions_transit_filled: "0xe1e0",
    directions_walk: "0xe1e1",
    dirty_lens: "0xe1e2",
    disabled_by_default: "0xe1e3",
    disc_full: "0xe1e4",
    discount: "0xf06bd",
    display_settings: "0xf04f1",
    dns: "0xe1e5",
    do_disturb: "0xe1e6",
    do_disturb_alt: "0xe1e7",
    do_disturb_off: "0xe1e8",
    do_disturb_on: "0xe1e9",
    do_not_disturb: "0xe1ea",
    do_not_disturb_alt: "0xe1eb",
    do_not_disturb_off: "0xe1ec",
    do_not_disturb_on: "0xe1ed",
    do_not_disturb_on_total_silence: "0xe1ee",
    do_not_step: "0xe1ef",
    do_not_touch: "0xe1f0",
    dock: "0xe1f1",
    document_scanner: "0xe1f2",
    domain: "0xe1f3",
    domain_add: "0xf04f2",
    domain_disabled: "0xe1f4",
    domain_verification: "0xe1f5",
    done: "0xe1f6",
    done_all: "0xe1f7",
    done_outline: "0xe1f8",
    donut_large: "0xe1f9",
    donut_small: "0xe1fa",
    door_sliding: "0xe1fd",
    doorbell: "0xe1fe",
    double_arrow: "0xe1ff",
    downhill_skiing: "0xe200",
    download: "0xe201",
    download_done: "0xe202",
    download_for_offline: "0xe203",
    downloading: "0xe204",
    drafts: "0xe205",
    drag_handle: "0xe206",
    drag_indicator: "0xe207",
    draw: "0xf04f3",
    drive_eta: "0xe208",
    drive_file_move: "0xe209",
    drive_file_rename_outline: "0xe20b",
    drive_folder_upload: "0xe20c",
    dry: "0xe20d",
    dry_cleaning: "0xe20e",
    duo: "0xe20f",
    dvr: "0xe210",
    dynamic_feed: "0xe211",
    dynamic_form: "0xe212",
    e_mobiledata: "0xe213",
    earbuds: "0xe214",
    earbuds_battery: "0xe215",
    east: "0xe216",
    edgesensor_high: "0xe218",
    edgesensor_low: "0xe219",
    edit: "0xe21a",
    edit_attributes: "0xe21b",
    edit_calendar: "0xf04f5",
    edit_location: "0xe21c",
    edit_location_alt: "0xe21d",
    edit_note: "0xf04f6",
    edit_notifications: "0xe21e",
    edit_off: "0xe21f",
    edit_road: "0xe220",
    egg: "0xf04f8",
    egg_alt: "0xf04f7",
    eject: "0xe221",
    elderly: "0xe222",
    elderly_woman: "0xf04f9",
    electric_bike: "0xe223",
    electric_bolt: "0xf079c",
    electric_car: "0xe224",
    electric_meter: "0xf079d",
    electric_moped: "0xe225",
    electric_rickshaw: "0xe226",
    electric_scooter: "0xe227",
    electrical_services: "0xe228",
    elevator: "0xe229",
    email: "0xe22a",
    emergency_recording: "0xf079e",
    emergency_share: "0xf079f",
    emoji_emotions: "0xe22b",
    emoji_events: "0xe22c",
    emoji_flags: "0xe22d",
    emoji_food_beverage: "0xe22e",
    emoji_nature: "0xe22f",
    emoji_objects: "0xe230",
    emoji_people: "0xe231",
    emoji_symbols: "0xe232",
    emoji_transportation: "0xe233",
    energy_savings_leaf: "0xf07a0",
    engineering: "0xe234",
    enhanced_encryption: "0xe235",
    equalizer: "0xe236",
    error: "0xe237",
    error_outline: "0xe238",
    escalator: "0xe239",
    escalator_warning: "0xe23a",
    euro: "0xe23b",
    euro_symbol: "0xe23c",
    ev_station: "0xe23d",
    event: "0xe23e",
    event_available: "0xe23f",
    event_busy: "0xe240",
    event_note: "0xe241",
    event_repeat: "0xf04fb",
    event_seat: "0xe242",
    exit_to_app: "0xe243",
    expand: "0xe244",
    expand_circle_down: "0xf04fc",
    expand_less: "0xe245",
    expand_more: "0xe246",
    explicit: "0xe247",
    explore: "0xe248",
    explore_off: "0xe249",
    exposure: "0xe24a",
    extension: "0xe250",
    extension_off: "0xe251",
    face: "0xe252",
    face_retouching_natural: "0xe253",
    face_retouching_off: "0xe254",
    facebook: "0xe255",
    fact_check: "0xe256",
    factory: "0xf04fd",
    family_restroom: "0xe257",
    fast_forward: "0xe258",
    fast_rewind: "0xe259",
    fastfood: "0xe25a",
    favorite: "0xe25b",
    favorite_border: "0xe25c",
    fax: "0xf04fe",
    featured_play_list: "0xe25d",
    featured_video: "0xe25e",
    feed: "0xe25f",
    feedback: "0xe260",
    female: "0xe261",
    fence: "0xe262",
    festival: "0xe263",
    fiber_dvr: "0xe264",
    fiber_manual_record: "0xe265",
    fiber_new: "0xe266",
    fiber_pin: "0xe267",
    fiber_smart_record: "0xe268",
    file_copy: "0xe269",
    file_download: "0xe26a",
    file_download_done: "0xe26b",
    file_download_off: "0xe26c",
    file_open: "0xf04ff",
    file_present: "0xe26d",
    file_upload: "0xe26e",
    filter: "0xe26f",
    filter_alt: "0xe27a",
    filter_alt_off: "0xf0500",
    filter_b_and_w: "0xe27b",
    filter_center_focus: "0xe27c",
    filter_drama: "0xe27d",
    filter_frames: "0xe27e",
    filter_hdr: "0xe27f",
    filter_list: "0xe280",
    filter_list_off: "0xf0501",
    filter_none: "0xe282",
    filter_tilt_shift: "0xe283",
    filter_vintage: "0xe284",
    find_in_page: "0xe285",
    find_replace: "0xe286",
    fingerprint: "0xe287",
    fire_extinguisher: "0xe288",
    fire_hydrant_alt: "0xf07a1",
    fire_truck: "0xf07a2",
    fireplace: "0xe28a",
    first_page: "0xe28b",
    fit_screen: "0xe28c",
    fitbit: "0xf0502",
    fitness_center: "0xe28d",
    flag: "0xe28e",
    flag_circle: "0xf0503",
    flaky: "0xe28f",
    flare: "0xe290",
    flash_auto: "0xe291",
    flash_off: "0xe292",
    flash_on: "0xe293",
    flashlight_off: "0xe294",
    flashlight_on: "0xe295",
    flatware: "0xe296",
    flight: "0xe297",
    flight_class: "0xf0504",
    flight_land: "0xe298",
    flight_takeoff: "0xe299",
    flip: "0xe29a",
    flip_camera_android: "0xe29b",
    flip_camera_ios: "0xe29c",
    flip_to_back: "0xe29d",
    flip_to_front: "0xe29e",
    flood: "0xf07a3",
    fluorescent: "0xf0865",
    flutter_dash: "0xe2a0",
    fmd_bad: "0xe2a1",
    fmd_good: "0xe2a2",
    folder: "0xe2a3",
    folder_copy: "0xf0506",
    folder_delete: "0xf0507",
    folder_off: "0xf0508",
    folder_open: "0xe2a4",
    folder_shared: "0xe2a5",
    folder_special: "0xe2a6",
    folder_zip: "0xf0509",
    follow_the_signs: "0xe2a7",
    font_download: "0xe2a8",
    font_download_off: "0xe2a9",
    food_bank: "0xe2aa",
    forest: "0xf050a",
    fork_left: "0xf050b",
    fork_right: "0xf050c",
    format_align_center: "0xe2ab",
    format_align_justify: "0xe2ac",
    format_align_left: "0xe2ad",
    format_align_right: "0xe2ae",
    format_bold: "0xe2af",
    format_clear: "0xe2b0",
    format_color_fill: "0xe2b1",
    format_color_reset: "0xe2b2",
    format_color_text: "0xe2b3",
    format_indent_decrease: "0xe2b4",
    format_indent_increase: "0xe2b5",
    format_italic: "0xe2b6",
    format_line_spacing: "0xe2b7",
    format_list_bulleted: "0xe2b8",
    format_list_numbered: "0xe2b9",
    format_list_numbered_rtl: "0xe2ba",
    format_overline: "0xf050d",
    format_paint: "0xe2bb",
    format_quote: "0xe2bc",
    format_shapes: "0xe2bd",
    format_size: "0xe2be",
    format_strikethrough: "0xe2bf",
    format_textdirection_l_to_r: "0xe2c0",
    format_textdirection_r_to_l: "0xe2c1",
    format_underlined: "0xe2c2",
    fort: "0xf050e",
    forum: "0xe2c3",
    forward: "0xe2c4",
    forward_to_inbox: "0xe2c8",
    foundation: "0xe2c9",
    free_breakfast: "0xe2ca",
    fullscreen: "0xe2cb",
    fullscreen_exit: "0xe2cc",
    functions: "0xe2cd",
    g_mobiledata: "0xe2ce",
    g_translate: "0xe2cf",
    gamepad: "0xe2d0",
    games: "0xe2d1",
    garage: "0xe2d2",
    gas_meter: "0xf07a4",
    gavel: "0xe2d3",
    gesture: "0xe2d4",
    get_app: "0xe2d5",
    gif: "0xe2d6",
    gif_box: "0xf0512",
    girl: "0xf0513",
    gite: "0xe2d7",
    golf_course: "0xe2d8",
    gpp_bad: "0xe2d9",
    gpp_good: "0xe2da",
    gpp_maybe: "0xe2db",
    gps_fixed: "0xe2dc",
    gps_not_fixed: "0xe2dd",
    gps_off: "0xe2de",
    grade: "0xe2df",
    gradient: "0xe2e0",
    grading: "0xe2e1",
    grain: "0xe2e2",
    graphic_eq: "0xe2e3",
    grass: "0xe2e4",
    grid_goldenratio: "0xe2e7",
    grid_off: "0xe2e8",
    grid_on: "0xe2e9",
    grid_view: "0xe2ea",
    group: "0xe2eb",
    group_add: "0xe2ec",
    group_remove: "0xf0515",
    group_work: "0xe2ed",
    groups: "0xe2ee",
    h_mobiledata: "0xe2ef",
    h_plus_mobiledata: "0xe2f0",
    hail: "0xe2f1",
    handshake: "0xf06be",
    handyman: "0xe2f2",
    hardware: "0xe2f3",
    hd: "0xe2f4",
    hdr_auto: "0xe2f5",
    hdr_auto_select: "0xe2f6",
    hdr_enhanced_select: "0xe2f7",
    hdr_off: "0xe2f8",
    hdr_off_select: "0xe2f9",
    hdr_on: "0xe2fa",
    hdr_on_select: "0xe2fb",
    hdr_plus: "0xe2fc",
    hdr_strong: "0xe2fd",
    hdr_weak: "0xe2fe",
    headphones: "0xe2ff",
    headphones_battery: "0xe300",
    headset: "0xe301",
    headset_mic: "0xe302",
    headset_off: "0xe303",
    healing: "0xe304",
    health_and_safety: "0xe305",
    hearing: "0xe306",
    hearing_disabled: "0xe307",
    heart_broken: "0xf0516",
    heat_pump: "0xf07a5",
    height: "0xe308",
    help: "0xe309",
    help_center: "0xe30a",
    help_outline: "0xe30b",
    hevc: "0xe30c",
    hexagon: "0xf0517",
    hide_image: "0xe30d",
    hide_source: "0xe30e",
    high_quality: "0xe30f",
    highlight: "0xe310",
    highlight_alt: "0xe311",
    highlight_off: "0xe312",
    hiking: "0xe313",
    history: "0xe314",
    history_edu: "0xe315",
    history_toggle_off: "0xe316",
    hive: "0xf0518",
    hls: "0xf0519",
    hls_off: "0xf051a",
    holiday_village: "0xe317",
    home: "0xe318",
    home_max: "0xe31a",
    home_mini: "0xe31b",
    home_repair_service: "0xe31c",
    home_work: "0xe31d",
    horizontal_rule: "0xe31f",
    horizontal_split: "0xe320",
    hot_tub: "0xe321",
    hotel: "0xe322",
    hourglass_bottom: "0xe323",
    hourglass_disabled: "0xe324",
    hourglass_empty: "0xe325",
    hourglass_full: "0xe326",
    hourglass_top: "0xe327",
    house: "0xe328",
    house_siding: "0xe329",
    houseboat: "0xe32a",
    how_to_reg: "0xe32b",
    how_to_vote: "0xe32c",
    html: "0xf051c",
    http: "0xe32d",
    https: "0xe32e",
    hub: "0xf051d",
    hvac: "0xe32f",
    ice_skating: "0xe330",
    icecream: "0xe331",
    image: "0xe332",
    image_aspect_ratio: "0xe333",
    image_not_supported: "0xe334",
    image_search: "0xe335",
    imagesearch_roller: "0xe336",
    import_contacts: "0xe337",
    import_export: "0xe338",
    important_devices: "0xe339",
    inbox: "0xe33a",
    indeterminate_check_box: "0xe33b",
    info: "0xe33c",
    input: "0xe33e",
    insert_chart: "0xe33f",
    insert_comment: "0xe341",
    insert_drive_file: "0xe342",
    insert_emoticon: "0xe343",
    insert_invitation: "0xe344",
    insert_link: "0xe345",
    insert_page_break: "0xf0520",
    insert_photo: "0xe346",
    insights: "0xe347",
    install_desktop: "0xf0521",
    install_mobile: "0xf0522",
    integration_instructions: "0xe348",
    interests: "0xf0523",
    interpreter_mode: "0xf0524",
    inventory: "0xe349",
    invert_colors: "0xe34b",
    invert_colors_off: "0xe34c",
    ios_share: "0xe34d",
    iron: "0xe34e",
    iso: "0xe34f",
    javascript: "0xf0525",
    join_full: "0xf0526",
    join_inner: "0xf0527",
    join_left: "0xf0528",
    join_right: "0xf0529",
    kayaking: "0xe350",
    kebab_dining: "0xf052a",
    key: "0xf052b",
    key_off: "0xf052c",
    keyboard: "0xe351",
    keyboard_alt: "0xe352",
    keyboard_arrow_down: "0xe353",
    keyboard_arrow_left: "0xe354",
    keyboard_arrow_right: "0xe355",
    keyboard_arrow_up: "0xe356",
    keyboard_backspace: "0xe357",
    keyboard_capslock: "0xe358",
    keyboard_command_key: "0xf052d",
    keyboard_control_key: "0xf052e",
    keyboard_double_arrow_down: "0xf052f",
    keyboard_double_arrow_left: "0xf0530",
    keyboard_double_arrow_right: "0xf0531",
    keyboard_double_arrow_up: "0xf0532",
    keyboard_hide: "0xe359",
    keyboard_option_key: "0xf0533",
    keyboard_return: "0xe35a",
    keyboard_tab: "0xe35b",
    keyboard_voice: "0xe35c",
    king_bed: "0xe35d",
    kitchen: "0xe35e",
    kitesurfing: "0xe35f",
    label: "0xe360",
    label_important: "0xe361",
    label_off: "0xe363",
    lan: "0xf0534",
    landscape: "0xe365",
    landslide: "0xf07a6",
    language: "0xe366",
    laptop: "0xe367",
    laptop_chromebook: "0xe368",
    laptop_mac: "0xe369",
    laptop_windows: "0xe36a",
    last_page: "0xe36b",
    launch: "0xe36c",
    layers: "0xe36d",
    layers_clear: "0xe36e",
    leaderboard: "0xe36f",
    leak_add: "0xe370",
    leak_remove: "0xe371",
    legend_toggle: "0xe372",
    lens: "0xe373",
    lens_blur: "0xe374",
    library_add: "0xe375",
    library_add_check: "0xe376",
    library_books: "0xe377",
    library_music: "0xe378",
    light: "0xe379",
    light_mode: "0xe37a",
    lightbulb: "0xe37b",
    lightbulb_circle: "0xf07a7",
    line_axis: "0xf0535",
    line_style: "0xe37d",
    line_weight: "0xe37e",
    linear_scale: "0xe37f",
    link: "0xe380",
    link_off: "0xe381",
    linked_camera: "0xe382",
    liquor: "0xe383",
    list: "0xe384",
    list_alt: "0xe385",
    live_help: "0xe386",
    live_tv: "0xe387",
    living: "0xe388",
    local_activity: "0xe389",
    local_airport: "0xe38a",
    local_atm: "0xe38b",
    local_bar: "0xe38c",
    local_cafe: "0xe38d",
    local_car_wash: "0xe38e",
    local_convenience_store: "0xe38f",
    local_dining: "0xe390",
    local_drink: "0xe391",
    local_fire_department: "0xe392",
    local_florist: "0xe393",
    local_gas_station: "0xe394",
    local_grocery_store: "0xe395",
    local_hospital: "0xe396",
    local_hotel: "0xe397",
    local_laundry_service: "0xe398",
    local_library: "0xe399",
    local_mall: "0xe39a",
    local_movies: "0xe39b",
    local_offer: "0xe39c",
    local_parking: "0xe39d",
    local_pharmacy: "0xe39e",
    local_phone: "0xe39f",
    local_pizza: "0xe3a0",
    local_play: "0xe3a1",
    local_police: "0xe3a2",
    local_post_office: "0xe3a3",
    local_printshop: "0xe3a4",
    local_see: "0xe3a5",
    local_shipping: "0xe3a6",
    local_taxi: "0xe3a7",
    location_city: "0xe3a8",
    location_disabled: "0xe3a9",
    location_off: "0xe3aa",
    location_on: "0xe3ab",
    location_searching: "0xe3ad",
    lock: "0xe3ae",
    lock_clock: "0xe3af",
    lock_open: "0xe3b0",
    lock_person: "0xf07a8",
    lock_reset: "0xf0536",
    login: "0xe3b2",
    logo_dev: "0xf0537",
    logout: "0xe3b3",
    looks: "0xe3b4",
    looks_one: "0xe3b9",
    looks_two: "0xe3ba",
    loop: "0xe3bb",
    loupe: "0xe3bc",
    low_priority: "0xe3bd",
    loyalty: "0xe3be",
    lte_mobiledata: "0xe3bf",
    lte_plus_mobiledata: "0xe3c0",
    luggage: "0xe3c1",
    lunch_dining: "0xe3c2",
    lyrics: "0xf07a9",
    macro_off: "0xf086b",
    mail: "0xe3c3",
    mail_lock: "0xf07aa",
    mail_outline: "0xe3c4",
    male: "0xe3c5",
    man: "0xf0538",
    manage_accounts: "0xe3c6",
    manage_history: "0xf07ab",
    manage_search: "0xe3c7",
    map: "0xe3c8",
    maps_home_work: "0xe3c9",
    maps_ugc: "0xe3ca",
    margin: "0xe3cb",
    mark_as_unread: "0xe3cc",
    mark_chat_read: "0xe3cd",
    mark_chat_unread: "0xe3ce",
    mark_email_read: "0xe3cf",
    mark_email_unread: "0xe3d0",
    mark_unread_chat_alt: "0xf0539",
    markunread: "0xe3d1",
    markunread_mailbox: "0xe3d2",
    masks: "0xe3d3",
    maximize: "0xe3d4",
    media_bluetooth_off: "0xe3d5",
    media_bluetooth_on: "0xe3d6",
    mediation: "0xe3d7",
    medical_information: "0xf07ac",
    medical_services: "0xe3d8",
    medication: "0xe3d9",
    medication_liquid: "0xf053a",
    meeting_room: "0xe3da",
    memory: "0xe3db",
    menu: "0xe3dc",
    menu_book: "0xe3dd",
    menu_open: "0xe3de",
    merge: "0xf053b",
    merge_type: "0xe3df",
    message: "0xe3e0",
    mic: "0xe3e1",
    mic_external_off: "0xe3e2",
    mic_external_on: "0xe3e3",
    mic_none: "0xe3e4",
    mic_off: "0xe3e5",
    microwave: "0xe3e6",
    military_tech: "0xe3e7",
    minimize: "0xe3e8",
    minor_crash: "0xf07ad",
    miscellaneous_services: "0xe3e9",
    missed_video_call: "0xe3ea",
    mms: "0xe3eb",
    mobile_friendly: "0xe3ec",
    mobile_off: "0xe3ed",
    mobile_screen_share: "0xe3ee",
    mobiledata_off: "0xe3ef",
    mode: "0xe3f0",
    mode_comment: "0xe3f1",
    mode_edit: "0xe3f2",
    mode_edit_outline: "0xe3f3",
    mode_fan_off: "0xf07ae",
    mode_night: "0xe3f4",
    mode_of_travel: "0xf053c",
    mode_standby: "0xe3f5",
    model_training: "0xe3f6",
    monetization_on: "0xe3f7",
    money: "0xe3f8",
    money_off: "0xe3f9",
    money_off_csred: "0xe3fa",
    monitor: "0xe3fb",
    monitor_heart: "0xf053d",
    monitor_weight: "0xe3fc",
    monochrome_photos: "0xe3fd",
    mood: "0xe3fe",
    mood_bad: "0xe3ff",
    moped: "0xe400",
    more: "0xe401",
    more_horiz: "0xe402",
    more_time: "0xe403",
    more_vert: "0xe404",
    mosque: "0xf053e",
    motion_photos_auto: "0xe405",
    motion_photos_off: "0xe406",
    mouse: "0xe40b",
    move_down: "0xf053f",
    move_to_inbox: "0xe40c",
    move_up: "0xf0540",
    movie: "0xe40d",
    movie_creation: "0xe40e",
    movie_filter: "0xe40f",
    moving: "0xe410",
    mp: "0xe411",
    multiline_chart: "0xe412",
    multiple_stop: "0xe413",
    museum: "0xe414",
    music_note: "0xe415",
    music_off: "0xe416",
    music_video: "0xe417",
    my_location: "0xe418",
    nat: "0xe419",
    nature: "0xe41a",
    nature_people: "0xe41b",
    navigate_before: "0xe41c",
    navigate_next: "0xe41d",
    navigation: "0xe41e",
    near_me: "0xe41f",
    near_me_disabled: "0xe420",
    nearby_error: "0xe421",
    nearby_off: "0xe422",
    nest_cam_wired_stand: "0xf07af",
    network_cell: "0xe423",
    network_check: "0xe424",
    network_locked: "0xe425",
    network_ping: "0xf06bf",
    network_wifi: "0xe426",
    new_releases: "0xe428",
    newspaper: "0xf0541",
    next_plan: "0xe429",
    next_week: "0xe42a",
    nfc: "0xe42b",
    night_shelter: "0xe42c",
    nightlife: "0xe42d",
    nightlight: "0xe42e",
    nightlight_round: "0xe42f",
    nights_stay: "0xe430",
    no_accounts: "0xe431",
    no_adult_content: "0xf07b3",
    no_backpack: "0xe432",
    no_cell: "0xe433",
    no_crash: "0xf07b4",
    no_drinks: "0xe434",
    no_encryption: "0xe435",
    no_encryption_gmailerrorred: "0xe436",
    no_flash: "0xe437",
    no_food: "0xe438",
    no_luggage: "0xe439",
    no_meals: "0xe43a",
    no_meeting_room: "0xe43c",
    no_photography: "0xe43d",
    no_sim: "0xe43e",
    no_stroller: "0xe43f",
    no_transfer: "0xe440",
    noise_aware: "0xf07b5",
    noise_control_off: "0xf07b6",
    nordic_walking: "0xe441",
    north: "0xe442",
    north_east: "0xe443",
    north_west: "0xe444",
    not_accessible: "0xe445",
    not_interested: "0xe446",
    not_listed_location: "0xe447",
    not_started: "0xe448",
    note: "0xe449",
    note_add: "0xe44a",
    note_alt: "0xe44b",
    notes: "0xe44c",
    notification_add: "0xe44d",
    notification_important: "0xe44e",
    notifications: "0xe44f",
    notifications_active: "0xe450",
    notifications_none: "0xe451",
    notifications_off: "0xe452",
    notifications_paused: "0xe453",
    numbers: "0xf0542",
    offline_bolt: "0xe454",
    offline_pin: "0xe455",
    offline_share: "0xe456",
    oil_barrel: "0xf07b7",
    on_device_training: "0xf07b8",
    ondemand_video: "0xe457",
    online_prediction: "0xe458",
    opacity: "0xe459",
    open_in_browser: "0xe45a",
    open_in_full: "0xe45b",
    open_in_new: "0xe45c",
    open_in_new_off: "0xe45d",
    open_with: "0xe45e",
    other_houses: "0xe45f",
    outbound: "0xe461",
    outbox: "0xe462",
    outdoor_grill: "0xe463",
    outlet: "0xe465",
    output: "0xf0543",
    padding: "0xe467",
    pages: "0xe468",
    pageview: "0xe469",
    paid: "0xe46a",
    palette: "0xe46b",
    pan_tool: "0xe46c",
    pan_tool_alt: "0xf0544",
    panorama: "0xe46d",
    panorama_fish_eye: "0xe46e",
    panorama_horizontal: "0xe46f",
    panorama_horizontal_select: "0xe470",
    panorama_photosphere: "0xe471",
    panorama_photosphere_select: "0xe472",
    panorama_vertical: "0xe473",
    panorama_vertical_select: "0xe474",
    panorama_wide_angle: "0xe475",
    panorama_wide_angle_select: "0xe476",
    paragliding: "0xe477",
    park: "0xe478",
    party_mode: "0xe479",
    password: "0xe47a",
    pattern: "0xe47b",
    pause: "0xe47c",
    pause_circle: "0xe47d",
    pause_circle_filled: "0xe47e",
    pause_circle_outline: "0xe47f",
    pause_presentation: "0xe480",
    payment: "0xe481",
    payments: "0xe482",
    pedal_bike: "0xe483",
    pending: "0xe484",
    pending_actions: "0xe485",
    pentagon: "0xf0546",
    people: "0xe486",
    people_alt: "0xe487",
    people_outline: "0xe488",
    percent: "0xf0547",
    perm_camera_mic: "0xe489",
    perm_contact_calendar: "0xe48a",
    perm_data_setting: "0xe48b",
    perm_device_information: "0xe48c",
    perm_identity: "0xe48d",
    perm_media: "0xe48e",
    perm_phone_msg: "0xe48f",
    perm_scan_wifi: "0xe490",
    person: "0xe491",
    person_add: "0xe492",
    person_add_alt: "0xe493",
    person_add_disabled: "0xe495",
    person_off: "0xe496",
    person_outline: "0xe497",
    person_pin: "0xe498",
    person_pin_circle: "0xe499",
    person_remove: "0xe49a",
    person_search: "0xe49c",
    personal_video: "0xe49e",
    pest_control: "0xe49f",
    pest_control_rodent: "0xe4a0",
    pets: "0xe4a1",
    phishing: "0xf0548",
    phone: "0xe4a2",
    phone_android: "0xe4a3",
    phone_bluetooth_speaker: "0xe4a4",
    phone_callback: "0xe4a5",
    phone_disabled: "0xe4a6",
    phone_enabled: "0xe4a7",
    phone_forwarded: "0xe4a8",
    phone_in_talk: "0xe4a9",
    phone_iphone: "0xe4aa",
    phone_locked: "0xe4ab",
    phone_missed: "0xe4ac",
    phone_paused: "0xe4ad",
    phonelink: "0xe4ae",
    phonelink_erase: "0xe4af",
    phonelink_lock: "0xe4b0",
    phonelink_off: "0xe4b1",
    phonelink_ring: "0xe4b2",
    phonelink_setup: "0xe4b3",
    photo: "0xe4b4",
    photo_album: "0xe4b5",
    photo_camera: "0xe4b6",
    photo_camera_back: "0xe4b7",
    photo_camera_front: "0xe4b8",
    photo_filter: "0xe4b9",
    photo_library: "0xe4ba",
    photo_size_select_actual: "0xe4bb",
    photo_size_select_large: "0xe4bc",
    photo_size_select_small: "0xe4bd",
    php: "0xf0549",
    piano: "0xe4be",
    piano_off: "0xe4bf",
    picture_as_pdf: "0xe4c0",
    picture_in_picture: "0xe4c1",
    picture_in_picture_alt: "0xe4c2",
    pie_chart: "0xe4c3",
    pie_chart_outline: "0xe4c5",
    pin: "0xe4c6",
    pin_drop: "0xe4c7",
    pinch: "0xf054d",
    pivot_table_chart: "0xe4c8",
    pix: "0xf054e",
    place: "0xe4c9",
    plagiarism: "0xe4ca",
    play_arrow: "0xe4cb",
    play_circle: "0xe4cc",
    play_circle_filled: "0xe4cd",
    play_circle_outline: "0xe4ce",
    play_disabled: "0xe4cf",
    play_for_work: "0xe4d0",
    play_lesson: "0xe4d1",
    playlist_add: "0xe4d2",
    playlist_add_check: "0xe4d3",
    playlist_add_check_circle: "0xf054f",
    playlist_add_circle: "0xf0550",
    playlist_play: "0xe4d4",
    playlist_remove: "0xf0551",
    plumbing: "0xe4d5",
    plus_one: "0xe4d6",
    podcasts: "0xe4d7",
    point_of_sale: "0xe4d8",
    policy: "0xe4d9",
    poll: "0xe4da",
    polyline: "0xf0552",
    pool: "0xe4dc",
    portable_wifi_off: "0xe4dd",
    portrait: "0xe4de",
    post_add: "0xe4df",
    power: "0xe4e0",
    power_input: "0xe4e1",
    power_off: "0xe4e2",
    power_settings_new: "0xe4e3",
    precision_manufacturing: "0xe4e4",
    pregnant_woman: "0xe4e5",
    present_to_all: "0xe4e6",
    preview: "0xe4e7",
    price_change: "0xe4e8",
    price_check: "0xe4e9",
    print: "0xe4ea",
    print_disabled: "0xe4eb",
    priority_high: "0xe4ec",
    privacy_tip: "0xe4ed",
    production_quantity_limits: "0xe4ee",
    propane: "0xf07b9",
    propane_tank: "0xf07ba",
    psychology: "0xe4ef",
    psychology_alt: "0xf0873",
    public: "0xe4f0",
    public_off: "0xe4f1",
    publish: "0xe4f2",
    published_with_changes: "0xe4f3",
    punch_clock: "0xf0554",
    push_pin: "0xe4f4",
    qr_code: "0xe4f5",
    qr_code_scanner: "0xe4f7",
    query_builder: "0xe4f8",
    query_stats: "0xe4f9",
    question_answer: "0xe4fa",
    question_mark: "0xf0555",
    queue: "0xe4fb",
    queue_music: "0xe4fc",
    queue_play_next: "0xe4fd",
    quickreply: "0xe4fe",
    quiz: "0xe4ff",
    r_mobiledata: "0xe500",
    radar: "0xe501",
    radio: "0xe502",
    radio_button_checked: "0xe503",
    radio_button_unchecked: "0xe504",
    railway_alert: "0xe505",
    ramen_dining: "0xe506",
    ramp_left: "0xf0557",
    ramp_right: "0xf0558",
    rate_review: "0xe507",
    raw_off: "0xe508",
    raw_on: "0xe509",
    read_more: "0xe50a",
    receipt: "0xe50c",
    receipt_long: "0xe50d",
    recent_actors: "0xe50e",
    recommend: "0xe50f",
    record_voice_over: "0xe510",
    rectangle: "0xf0559",
    recycling: "0xf055a",
    reddit: "0xf055b",
    redeem: "0xe511",
    redo: "0xe512",
    reduce_capacity: "0xe513",
    refresh: "0xe514",
    remember_me: "0xe515",
    remove: "0xe516",
    remove_circle: "0xe517",
    remove_circle_outline: "0xe518",
    remove_done: "0xe519",
    remove_from_queue: "0xe51a",
    remove_moderator: "0xe51b",
    remove_red_eye: "0xe51c",
    remove_road: "0xf07bb",
    remove_shopping_cart: "0xe51d",
    reorder: "0xe51e",
    repartition: "0xf0875",
    repeat: "0xe51f",
    repeat_on: "0xe520",
    repeat_one: "0xe521",
    repeat_one_on: "0xe522",
    replay: "0xe523",
    replay_circle_filled: "0xe527",
    reply: "0xe528",
    reply_all: "0xe529",
    report: "0xe52a",
    report_gmailerrorred: "0xe52b",
    report_off: "0xe52c",
    report_problem: "0xe52d",
    request_page: "0xe52e",
    request_quote: "0xe52f",
    reset_tv: "0xe530",
    restart_alt: "0xe531",
    restaurant: "0xe532",
    restaurant_menu: "0xe533",
    restore: "0xe534",
    restore_from_trash: "0xe535",
    restore_page: "0xe536",
    reviews: "0xe537",
    rice_bowl: "0xe538",
    ring_volume: "0xe539",
    rocket: "0xf055c",
    rocket_launch: "0xf055d",
    roller_shades: "0xf07bc",
    roller_shades_closed: "0xf07bd",
    roller_skating: "0xf06c0",
    roofing: "0xe53a",
    room: "0xe53b",
    room_preferences: "0xe53c",
    room_service: "0xe53d",
    rotate_left: "0xe53f",
    rotate_right: "0xe540",
    roundabout_left: "0xf055f",
    roundabout_right: "0xf0560",
    route: "0xf0561",
    router: "0xe542",
    rowing: "0xe543",
    rss_feed: "0xe544",
    rsvp: "0xe545",
    rtt: "0xe546",
    rule: "0xe547",
    rule_folder: "0xe548",
    run_circle: "0xe549",
    running_with_errors: "0xe54a",
    rv_hookup: "0xe54b",
    safety_check: "0xf07be",
    safety_divider: "0xe54c",
    sailing: "0xe54d",
    sanitizer: "0xe54e",
    satellite: "0xe54f",
    satellite_alt: "0xf0562",
    save: "0xe550",
    save_alt: "0xe551",
    save_as: "0xf0563",
    saved_search: "0xe552",
    savings: "0xe553",
    scale: "0xf0564",
    scanner: "0xe554",
    scatter_plot: "0xe555",
    schedule: "0xe556",
    schedule_send: "0xe557",
    schema: "0xe558",
    school: "0xe559",
    science: "0xe55a",
    score: "0xe55b",
    scoreboard: "0xf06c1",
    screen_lock_landscape: "0xe55c",
    screen_lock_portrait: "0xe55d",
    screen_lock_rotation: "0xe55e",
    screen_rotation: "0xe55f",
    screen_rotation_alt: "0xf07bf",
    screen_search_desktop: "0xe560",
    screen_share: "0xe561",
    screenshot: "0xe562",
    screenshot_monitor: "0xf07c0",
    scuba_diving: "0xf06c2",
    sd: "0xe563",
    sd_card: "0xe564",
    sd_card_alert: "0xe565",
    sd_storage: "0xe566",
    search: "0xe567",
    search_off: "0xe568",
    security: "0xe569",
    security_update: "0xe56a",
    security_update_good: "0xe56b",
    security_update_warning: "0xe56c",
    segment: "0xe56d",
    select_all: "0xe56e",
    self_improvement: "0xe56f",
    sell: "0xe570",
    send: "0xe571",
    send_and_archive: "0xe572",
    send_time_extension: "0xf0565",
    send_to_mobile: "0xe573",
    sensor_door: "0xe574",
    sensor_occupied: "0xf07c1",
    sensor_window: "0xe575",
    sensors: "0xe576",
    sensors_off: "0xe577",
    sentiment_dissatisfied: "0xe578",
    sentiment_neutral: "0xe579",
    sentiment_satisfied: "0xe57a",
    sentiment_satisfied_alt: "0xe57b",
    sentiment_very_dissatisfied: "0xe57c",
    sentiment_very_satisfied: "0xe57d",
    set_meal: "0xe57e",
    settings: "0xe57f",
    settings_accessibility: "0xe580",
    settings_applications: "0xe581",
    settings_backup_restore: "0xe582",
    settings_bluetooth: "0xe583",
    settings_brightness: "0xe584",
    settings_cell: "0xe585",
    settings_ethernet: "0xe586",
    settings_input_antenna: "0xe587",
    settings_input_component: "0xe588",
    settings_input_composite: "0xe589",
    settings_input_hdmi: "0xe58a",
    settings_input_svideo: "0xe58b",
    settings_overscan: "0xe58c",
    settings_phone: "0xe58d",
    settings_power: "0xe58e",
    settings_remote: "0xe58f",
    settings_suggest: "0xe590",
    settings_system_daydream: "0xe591",
    settings_voice: "0xe592",
    severe_cold: "0xf07c2",
    shape_line: "0xf0876",
    share: "0xe593",
    share_location: "0xe595",
    shield: "0xe596",
    shield_moon: "0xf0566",
    shop: "0xe597",
    shop_two: "0xe599",
    shopping_bag: "0xe59a",
    shopping_basket: "0xe59b",
    shopping_cart: "0xe59c",
    shopping_cart_checkout: "0xf0568",
    short_text: "0xe59d",
    shortcut: "0xe59e",
    show_chart: "0xe59f",
    shower: "0xe5a0",
    shuffle: "0xe5a1",
    shuffle_on: "0xe5a2",
    shutter_speed: "0xe5a3",
    sick: "0xe5a4",
    sign_language: "0xf07c3",
    signal_cellular_alt: "0xe5a7",
    signal_cellular_no_sim: "0xe5aa",
    signal_cellular_nodata: "0xe5ab",
    signal_cellular_null: "0xe5ac",
    signal_cellular_off: "0xe5ad",
    signal_wifi_bad: "0xe5b1",
    signal_wifi_off: "0xe5b3",
    signal_wifi_statusbar_null: "0xe5b6",
    signpost: "0xf0569",
    sim_card: "0xe5b7",
    sim_card_alert: "0xe5b8",
    sim_card_download: "0xe5b9",
    single_bed: "0xe5ba",
    sip: "0xe5bb",
    skateboarding: "0xe5bc",
    skip_next: "0xe5bd",
    skip_previous: "0xe5be",
    sledding: "0xe5bf",
    slideshow: "0xe5c0",
    slow_motion_video: "0xe5c1",
    smart_button: "0xe5c2",
    smart_display: "0xe5c3",
    smart_screen: "0xe5c4",
    smart_toy: "0xe5c5",
    smartphone: "0xe5c6",
    smoke_free: "0xe5c7",
    smoking_rooms: "0xe5c8",
    sms: "0xe5c9",
    sms_failed: "0xe5ca",
    snippet_folder: "0xe5cb",
    snooze: "0xe5cc",
    snowboarding: "0xe5cd",
    snowmobile: "0xe5ce",
    snowshoeing: "0xe5cf",
    soap: "0xe5d0",
    social_distance: "0xe5d1",
    solar_power: "0xf07c6",
    sort: "0xe5d2",
    sort_by_alpha: "0xe5d3",
    sos: "0xf07c7",
    soup_kitchen: "0xf056c",
    source: "0xe5d4",
    south: "0xe5d5",
    south_america: "0xf056d",
    south_east: "0xe5d6",
    south_west: "0xe5d7",
    spa: "0xe5d8",
    space_bar: "0xe5d9",
    space_dashboard: "0xe5da",
    spatial_audio: "0xf07c8",
    spatial_audio_off: "0xf07c9",
    spatial_tracking: "0xf07ca",
    speaker: "0xe5db",
    speaker_group: "0xe5dc",
    speaker_notes: "0xe5dd",
    speaker_notes_off: "0xe5de",
    speaker_phone: "0xe5df",
    speed: "0xe5e0",
    spellcheck: "0xe5e1",
    splitscreen: "0xe5e2",
    spoke: "0xf056e",
    sports: "0xe5e3",
    sports_bar: "0xe5e4",
    sports_baseball: "0xe5e5",
    sports_basketball: "0xe5e6",
    sports_cricket: "0xe5e7",
    sports_esports: "0xe5e8",
    sports_football: "0xe5e9",
    sports_golf: "0xe5ea",
    sports_gymnastics: "0xf06c3",
    sports_handball: "0xe5eb",
    sports_hockey: "0xe5ec",
    sports_kabaddi: "0xe5ed",
    sports_martial_arts: "0xf056f",
    sports_mma: "0xe5ee",
    sports_motorsports: "0xe5ef",
    sports_rugby: "0xe5f0",
    sports_score: "0xe5f1",
    sports_soccer: "0xe5f2",
    sports_tennis: "0xe5f3",
    sports_volleyball: "0xe5f4",
    square: "0xf0570",
    square_foot: "0xe5f5",
    ssid_chart: "0xf0571",
    stacked_bar_chart: "0xe5f6",
    stacked_line_chart: "0xe5f7",
    stadium: "0xf0572",
    stairs: "0xe5f8",
    star: "0xe5f9",
    star_border: "0xe5fa",
    star_border_purple500: "0xe5fb",
    star_half: "0xe5fc",
    star_outline: "0xe5fd",
    star_purple500: "0xe5fe",
    star_rate: "0xe5ff",
    stars: "0xe600",
    start: "0xf0573",
    stay_current_landscape: "0xe601",
    stay_current_portrait: "0xe602",
    stay_primary_landscape: "0xe603",
    stay_primary_portrait: "0xe604",
    stop: "0xe606",
    stop_circle: "0xe607",
    stop_screen_share: "0xe608",
    storage: "0xe609",
    store: "0xe60a",
    store_mall_directory: "0xe60b",
    storefront: "0xe60c",
    storm: "0xe60d",
    straight: "0xf0574",
    straighten: "0xe60e",
    stream: "0xe60f",
    streetview: "0xe610",
    strikethrough_s: "0xe611",
    stroller: "0xe612",
    style: "0xe613",
    subdirectory_arrow_left: "0xe614",
    subdirectory_arrow_right: "0xe615",
    subject: "0xe616",
    subscript: "0xe617",
    subscriptions: "0xe618",
    subtitles: "0xe619",
    subtitles_off: "0xe61a",
    subway: "0xe61b",
    summarize: "0xe61c",
    superscript: "0xe61d",
    supervised_user_circle: "0xe61e",
    supervisor_account: "0xe61f",
    support: "0xe620",
    support_agent: "0xe621",
    surfing: "0xe622",
    surround_sound: "0xe623",
    swap_calls: "0xe624",
    swap_horiz: "0xe625",
    swap_horizontal_circle: "0xe626",
    swap_vert: "0xe627",
    swap_vertical_circle: "0xe628",
    swipe: "0xe629",
    swipe_down: "0xf0578",
    swipe_down_alt: "0xf0577",
    swipe_left: "0xf057a",
    swipe_left_alt: "0xf0579",
    swipe_right: "0xf057c",
    swipe_right_alt: "0xf057b",
    swipe_up: "0xf057e",
    swipe_up_alt: "0xf057d",
    swipe_vertical: "0xf057f",
    switch_access_shortcut: "0xf0581",
    switch_access_shortcut_add: "0xf0580",
    switch_account: "0xe62a",
    switch_camera: "0xe62b",
    switch_left: "0xe62c",
    switch_right: "0xe62d",
    switch_video: "0xe62e",
    synagogue: "0xf0582",
    sync: "0xe62f",
    sync_alt: "0xe630",
    sync_disabled: "0xe631",
    sync_lock: "0xf0583",
    sync_problem: "0xe632",
    system_security_update: "0xe633",
    system_security_update_good: "0xe634",
    system_security_update_warning: "0xe635",
    system_update: "0xe636",
    system_update_alt: "0xe637",
    tab: "0xe638",
    tab_unselected: "0xe639",
    table_bar: "0xf0584",
    table_chart: "0xe63a",
    table_restaurant: "0xf0585",
    table_rows: "0xe63b",
    table_view: "0xe63c",
    tablet: "0xe63d",
    tablet_android: "0xe63e",
    tablet_mac: "0xe63f",
    tag: "0xe640",
    tag_faces: "0xe641",
    takeout_dining: "0xe642",
    tap_and_play: "0xe643",
    tapas: "0xe644",
    task: "0xe645",
    task_alt: "0xe646",
    taxi_alert: "0xe647",
    telegram: "0xf0586",
    temple_buddhist: "0xf0587",
    temple_hindu: "0xf0588",
    terminal: "0xf0589",
    terrain: "0xe648",
    text_decrease: "0xf058a",
    text_fields: "0xe649",
    text_format: "0xe64a",
    text_increase: "0xf058b",
    text_rotate_up: "0xe64b",
    text_rotate_vertical: "0xe64c",
    text_rotation_angledown: "0xe64d",
    text_rotation_angleup: "0xe64e",
    text_rotation_down: "0xe64f",
    text_rotation_none: "0xe650",
    text_snippet: "0xe651",
    textsms: "0xe652",
    texture: "0xe653",
    theater_comedy: "0xe654",
    theaters: "0xe655",
    thermostat: "0xe656",
    thermostat_auto: "0xe657",
    thumb_down: "0xe658",
    thumb_down_alt: "0xe659",
    thumb_down_off_alt: "0xe65a",
    thumb_up: "0xe65b",
    thumb_up_alt: "0xe65c",
    thumb_up_off_alt: "0xe65d",
    thumbs_up_down: "0xe65e",
    thunderstorm: "0xf07cb",
    time_to_leave: "0xe65f",
    timelapse: "0xe660",
    timeline: "0xe661",
    timer: "0xe662",
    timer_off: "0xe667",
    tips_and_updates: "0xf058d",
    tire_repair: "0xf06c4",
    title: "0xe668",
    toc: "0xe669",
    today: "0xe66a",
    toggle_off: "0xe66b",
    toggle_on: "0xe66c",
    token: "0xf058e",
    toll: "0xe66d",
    tonality: "0xe66e",
    topic: "0xe66f",
    tornado: "0xf07cc",
    touch_app: "0xe670",
    tour: "0xe671",
    toys: "0xe672",
    track_changes: "0xe673",
    traffic: "0xe674",
    train: "0xe675",
    tram: "0xe676",
    transcribe: "0xf07cd",
    transfer_within_a_station: "0xe677",
    transform: "0xe678",
    transgender: "0xe679",
    transit_enterexit: "0xe67a",
    translate: "0xe67b",
    travel_explore: "0xe67c",
    trending_down: "0xe67d",
    trending_flat: "0xe67e",
    trending_up: "0xe67f",
    trip_origin: "0xe680",
    troubleshoot: "0xf07ce",
    tsunami: "0xf07cf",
    tty: "0xe682",
    tune: "0xe683",
    tungsten: "0xe684",
    turn_left: "0xf058f",
    turn_right: "0xf0590",
    turn_slight_left: "0xf0593",
    turn_slight_right: "0xf0594",
    turned_in: "0xe685",
    turned_in_not: "0xe686",
    tv: "0xe687",
    tv_off: "0xe688",
    two_wheeler: "0xe689",
    type_specimen: "0xf07d0",
    u_turn_left: "0xf0595",
    u_turn_right: "0xf0596",
    umbrella: "0xe68a",
    unarchive: "0xe68b",
    undo: "0xe68c",
    unfold_less: "0xe68d",
    unfold_less_double: "0xf0879",
    unfold_more: "0xe68e",
    unfold_more_double: "0xf087a",
    unpublished: "0xe68f",
    unsubscribe: "0xe690",
    upcoming: "0xe691",
    update: "0xe692",
    update_disabled: "0xe693",
    upgrade: "0xe694",
    upload: "0xe695",
    upload_file: "0xe696",
    usb: "0xe697",
    usb_off: "0xe698",
    vaccines: "0xf0597",
    vape_free: "0xf06c5",
    vaping_rooms: "0xf06c6",
    verified: "0xe699",
    verified_user: "0xe69a",
    vertical_align_bottom: "0xe69b",
    vertical_align_center: "0xe69c",
    vertical_align_top: "0xe69d",
    vertical_shades: "0xf07d1",
    vertical_shades_closed: "0xf07d2",
    vertical_split: "0xe69f",
    vibration: "0xe6a0",
    video_call: "0xe6a1",
    video_camera_back: "0xe6a2",
    video_camera_front: "0xe6a3",
    video_chat: "0xf087b",
    video_file: "0xf0598",
    video_label: "0xe6a4",
    video_library: "0xe6a5",
    video_settings: "0xe6a6",
    video_stable: "0xe6a7",
    videocam: "0xe6a8",
    videocam_off: "0xe6a9",
    videogame_asset: "0xe6aa",
    videogame_asset_off: "0xe6ab",
    view_agenda: "0xe6ac",
    view_array: "0xe6ad",
    view_carousel: "0xe6ae",
    view_column: "0xe6af",
    view_comfy: "0xe6b0",
    view_comfy_alt: "0xf0599",
    view_compact: "0xe6b1",
    view_compact_alt: "0xf059a",
    view_cozy: "0xf059b",
    view_day: "0xe6b2",
    view_headline: "0xe6b3",
    view_in_ar: "0xe6b4",
    view_kanban: "0xf059c",
    view_list: "0xe6b5",
    view_module: "0xe6b6",
    view_quilt: "0xe6b7",
    view_sidebar: "0xe6b8",
    view_stream: "0xe6b9",
    view_timeline: "0xf059d",
    view_week: "0xe6ba",
    vignette: "0xe6bb",
    villa: "0xe6bc",
    visibility: "0xe6bd",
    visibility_off: "0xe6be",
    voice_chat: "0xe6bf",
    voice_over_off: "0xe6c0",
    voicemail: "0xe6c1",
    volcano: "0xf07d3",
    volume_down: "0xe6c2",
    volume_mute: "0xe6c3",
    volume_off: "0xe6c4",
    volume_up: "0xe6c5",
    volunteer_activism: "0xe6c6",
    vpn_key: "0xe6c7",
    vpn_key_off: "0xf059f",
    vpn_lock: "0xe6c8",
    vrpano: "0xe6c9",
    wallet: "0xf07d4",
    wallpaper: "0xe6ca",
    warehouse: "0xf05a0",
    warning: "0xe6cb",
    warning_amber: "0xe6cc",
    wash: "0xe6cd",
    watch: "0xe6ce",
    watch_later: "0xe6cf",
    watch_off: "0xf05a1",
    water: "0xe6d0",
    water_damage: "0xe6d1",
    water_drop: "0xf05a2",
    waterfall_chart: "0xe6d2",
    waves: "0xe6d3",
    waving_hand: "0xf05a3",
    wb_auto: "0xe6d4",
    wb_cloudy: "0xe6d5",
    wb_incandescent: "0xe6d6",
    wb_iridescent: "0xe6d7",
    wb_shade: "0xe6d8",
    wb_sunny: "0xe6d9",
    wb_twilight: "0xe6db",
    wc: "0xe6dc",
    web: "0xe6dd",
    web_asset: "0xe6de",
    web_asset_off: "0xe6df",
    web_stories: "0xe6e0",
    webhook: "0xf05a4",
    weekend: "0xe6e1",
    west: "0xe6e2",
    whatshot: "0xe6e3",
    wheelchair_pickup: "0xe6e4",
    where_to_vote: "0xe6e5",
    widgets: "0xe6e6",
    width_full: "0xf07d5",
    width_normal: "0xf07d6",
    width_wide: "0xf07d7",
    wifi: "0xe6e7",
    wifi_calling: "0xe6e8",
    wifi_channel: "0xf05a7",
    wifi_find: "0xf05a8",
    wifi_lock: "0xe6ea",
    wifi_off: "0xe6eb",
    wifi_password: "0xf05a9",
    wifi_protected_setup: "0xe6ec",
    wifi_tethering: "0xe6ed",
    wifi_tethering_error: "0xf05aa",
    wifi_tethering_off: "0xe6ef",
    wind_power: "0xf07da",
    window: "0xe6f0",
    wine_bar: "0xe6f1",
    woman: "0xf05ab",
    work: "0xe6f2",
    work_history: "0xf07db",
    work_off: "0xe6f3",
    work_outline: "0xe6f4",
    workspace_premium: "0xf05ae",
    workspaces: "0xe6f5",
    wrap_text: "0xe6f8",
    wrong_location: "0xe6f9",
    wysiwyg: "0xe6fa",
    yard: "0xe6fb",
    youtube_searched_for: "0xe6fc",
    zoom_in: "0xe6fd",
    zoom_in_map: "0xf05af",
    zoom_out: "0xe6fe",
    zoom_out_map: "0xe6ff"
}