import React, { useState } from 'react';
import { Document, Page, pdfjs, Thumbnail } from 'react-pdf';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography, Grid, Button, Card, CardHeader, CardContent } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import ModalTemplate from "../../../components/modal/modalTemplate";

// Update PDF.js worker configuration
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const JobCertificates = (props) => {
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [previewedCertificateTitle, setPreviewedCertificateTitle] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => {
            const newPage = prevPageNumber + offset;
            return Math.min(Math.max(1, newPage), numPages);
        });
    };

    const handlePreview = (certificate) => {
        const certificateUrl = certificate.job_certificate_path;
        setSelectedPdf(certificateUrl);
        setPreviewedCertificateTitle(certificate.job_certificate_title);
        setShowModal(true);
    };

    const handleDownload = async (certificateUrl, fileName) => {
        try {
            const response = await fetch(certificateUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName || 'certificate.pdf';
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <Grid item xs={12} md={6} lg={12}>
            <Card sx={{ height: '100%', mb: 4 }}>
                <CardHeader
                    title={
                        <Typography sx={{fontSize: '1rem', lineHeight: 1.625, fontWeight: 600, color: '#344767'}}>
                            JOB CERTIFICATES
                        </Typography>
                    }
                />
                <CardContent sx={{ pt: 4, p: 3 }}>
                    {props.job_certificates.length > 0 ? (
                        <Grid container spacing={2} className="certificates-grid">
                            {props.job_certificates?.map((certificate, index) => (
                                <Grid item key={index} className="certificate-item">
                                    <Box className="pdf-thumbnail-container">
                                        <Document 
                                            file={certificate.job_certificate_path}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            <Thumbnail pageNumber={1} width={150} />
                                        </Document>
                                        <Box className="hover-actions">
                                            <Tooltip title="Preview">
                                                <Button
                                                    className="icon-button preview"
                                                    onClick={() => handlePreview(certificate)}
                                                >
                                                    <VisibilityIcon />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Download">
                                                <Button
                                                    className="icon-button download"
                                                    onClick={() => handleDownload(certificate.job_certificate_path, `certificate-${index + 1}.pdf`)}
                                                >
                                                    <DownloadIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Typography variant='subtitle2' className="certificate-title">
                                        {certificate.job_certificate_title} | {certificate.job_certificates_type.job_certificates_type_name}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography variant="body1">Sorry, no certificates found!</Typography>
                    )}
                </CardContent>
            </Card>

            <ModalTemplate
                show={showModal}
                closeFun={() => {
                    setShowModal(false);
                    setPageNumber(1);
                }}
                size="lg"
                centered={true}
                title={previewedCertificateTitle}
                body={
                    <Box className="pdf-preview">
                        <Document 
                            file={selectedPdf}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page 
                                pageNumber={pageNumber} 
                                width={750} 
                                renderTextLayer={false}
                            />
                        </Document>
                        <Box className="pdf-navigation">
                            <Tooltip title="Previous page">
                                <Button 
                                    className="nav-button"
                                    onClick={() => changePage(-1)}
                                    disabled={pageNumber <= 1}
                                >
                                    <ChevronLeftIcon />
                                </Button>
                            </Tooltip>
                            <Typography className="page-info">
                                Page {pageNumber} of {numPages}
                            </Typography>
                            <Tooltip title="Next page">
                                <Button 
                                    className="nav-button"
                                    onClick={() => changePage(1)}
                                    disabled={pageNumber >= numPages}
                                >
                                    <ChevronRightIcon />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                }
            />
        </Grid>
    );
};

export default JobCertificates;