import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { GET } from "../../API/get";
import { POST } from "../../API/post";
import { PUT } from '../../API/put';
import { DELETE } from '../../API/delete';
import { commonSwal } from '../../components/swalAlert/swalAlert';

export const loadData = async (dispatch) => {
    dispatch({ type: "IS_LOADING" });

    const response = await GET("skills/only");

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            skills: response.skills
        });
    }
}

export const saveNewSubSkill = async (data, dispatch, resetForm) => {
    if(data.skill_name){
        dispatch({
            type: 'SET_IS_LOADING_BTN',
            btn_loading: true
        });

        let response = await POST("skills", {
            skill_name : data.skill_name,
            skill_hourly_rate : 0,
            skill_company_commission : 0,
            skill_customer_web_app_icon: data.skill_customer_web_app_icon,
            skill_customer_mobile_app_icon: data.skill_customer_mobile_app_icon,
        });

        if(response.status === "Success"){
            toast.success(response.message);
            resetForm();
            loadData(dispatch);
        }else{
            dispatch({
                type: 'SET_ERROR',
                error: response.message
            });     
        }
    }else{
        dispatch({
            type: 'SET_ERROR',
            error: "Please select name"
        });
    }
}

export const editSubSkill = async (event, skill_id, dispatch, reset) => {
    event.preventDefault();
    console.log("editSubSkill", event.target.elements.skill_name.value);
    let skill_name = event.target.elements.skill_name.value;
    let skill_customer_web_app_icon = event.target.elements.skill_customer_web_app_icon.value;
    let skill_customer_mobile_app_icon = event.target.elements.skill_customer_mobile_app_icon.value;
    if(skill_name){
        dispatch({
            type: 'SET_IS_LOADING_BTN',
            btn_loading: true
        });

        let response = await PUT("skills", {
            skill_id: skill_id,
            skill_name : skill_name,
            skill_customer_web_app_icon: skill_customer_web_app_icon,
            skill_customer_mobile_app_icon: skill_customer_mobile_app_icon,
        });

        if(response.status === "Success"){
            toast.success(response.message);
            reset();
            loadData(dispatch);
        }else{
            dispatch({
                type: 'SET_ERROR',
                error: response.message
            });     
        }
    }else{
        dispatch({
            type: 'SET_ERROR',
            error: "Please select name"
        });
    }
}

export const deleteSkills = (skill_id, dispatch) => {
    commonSwal({
        type: 'DELETE_SKILLS',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE("skills/"+skill_id);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        console.log('result :', result);
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                loadData(dispatch);
            }
        }
    })
}