import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

import Context from '../context';
import { deleteJobCertificateType } from '../service';
import { truncateText } from '../../../utils';

export default function CertificateTypesList() {
    const context = useContext(Context);

    return (
        <Box sx={{ flexGrow: 1}} className="m-0">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <List>
                        {context.state.job_certificates_types && context.state.job_certificates_types.length > 0 ? (
                            context.state.job_certificates_types?.map((job_certificate_type, index) => (
                                <ListItem
                                    key = {index}
                                    secondaryAction={
                                        <>
                                            <IconButton 
                                                edge="end" 
                                                onClick={() => context.dispatch({
                                                    type: "SELECTED_CERTIFICATE_TYPE",
                                                    selected_job_certificate_type: job_certificate_type
                                                })}
                                            >
                                                <DriveFileRenameOutlineIcon/>
                                            </IconButton>
                                            <IconButton 
                                                edge="end" 
                                                onClick={() => deleteJobCertificateType(job_certificate_type, context.dispatch)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CardMembershipIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={truncateText(job_certificate_type.job_certificates_type_name, 25)}
                                    />
                                </ListItem>
                            ))
                        ) : (
                            <Typography 
                                variant="subtitle1"
                                align="center">
                                    No certificate types found.
                            </Typography>
                        )}
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
}