import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { GET } from "../../API/get";
import { POST } from "../../API/post";
import { PUT } from "../../API/put";
import { theme } from '../../theme';
import { DELETE } from '../../API/delete';
import { commonSwal } from '../../components/swalAlert/swalAlert';

export const loadData = async (sub_skill_id, dispatch) => {
    dispatch({ type: "IS_LOADING" });

    let response = await GET(`job-certificate/types/${sub_skill_id}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            job_certificates_types: response.job_certificates_types
        });
    }
}

export const saveNewJobCertificateType = async (sub_skill_id, data, dispatch, reset) => {
    if(data.job_certificates_type_name){
        dispatch({ 
            type: "SET_IS_LOADING_BTN", 
            btn_loading: true
        });

        let response = await POST("job-certificates-types", {
            job_certificates_type_sub_skill_id : sub_skill_id,
            job_certificates_type_name : data.job_certificates_type_name,
            job_certificates_type_is_mandatory : data.job_certificates_type_is_mandatory ? 1 : 0
        });

        if(response.status === "Success"){
            toast.success(response.message);
            reset();
            loadData(sub_skill_id, dispatch);
        }
    }else{
        dispatch({
            type: 'SET_ERROR',
            error: 'Please add certificate type name'
        });
    }
}

export const updateJobCertificateType = async (event, selected_job_certificate_type, dispatch, reset) => {
    event.preventDefault();
    
    const job_certificates_type_name = event.target.elements.job_certificates_type_name.value;
    const job_certificates_type_is_mandatory = event.target.elements.job_certificates_type_is_mandatory.checked; // Get checkbox value

    if(job_certificates_type_name) {
        dispatch({ 
            type: "SET_IS_LOADING_BTN", 
            btn_loading: true
        });

        const response = await PUT("job-certificates-types", {
            job_certificates_type_id: selected_job_certificate_type.job_certificates_type_id,
            job_certificates_type_name: job_certificates_type_name,
            job_certificates_type_is_mandatory: job_certificates_type_is_mandatory ? 1 : 0 // Send as 1 or 0 based on checkbox status
        });

        if(response.status === "Success") {
            toast.success(response.message);
            reset();
            loadData(selected_job_certificate_type.job_certificates_type_sub_skill_id, dispatch);
        }
    } else {
        dispatch({
            type: 'SET_ERROR',
            error: 'Please add certificate type name'
        });
    }
}

export const deleteJobCertificateType = async (selected_job_certificate_type, dispatch) => {
    commonSwal({
        type: 'DELETE_CERTIFICATE',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE("job-certificates-types/" + selected_job_certificate_type.job_certificates_type_id);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        console.log('result :', result);
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                toast.success(result.value.message);
                loadData(selected_job_certificate_type.job_certificates_type_sub_skill_id, dispatch);
            }
        }
    })
}