export default function Reducer(prevState, action){
    switch (action.type) {
        case "ICON_LIST": 
            return {
                ...prevState,
                icon_list: action.icon_list,
                icon_count: action.icon_count
            }; 
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                current_page: action.current_page,
                icons_per_page: 20
            };
        case "SET_SEARCH_VALUE":
            return {
                ...prevState,
                current_page: 1,
                search_value: action.search_value,
            };
        default:
            throw new Error();
    }
}