import React, { useEffect, useReducer } from 'react';
import Collapse from '@mui/material/Collapse';

import Reducer from './reducer';
import { initialState } from './initialState';
import { loadData } from './service';
import Html from './html';
import Context from "./context";
import DivSpinner from '../../components/divSpinner';
import CollapseButton from './components/collapseButton';
import AddCertificateType from './components/addCertificateType';
import EditCertificateType from './components/editCertificateType';

const JobCertificateTypes = ({sub_skill, key}) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(sub_skill?.sub_skill_id, dispatch);
    }, [sub_skill?.sub_skill_id]);

    return (
        <Context.Provider value={{sub_skill_id:sub_skill?.sub_skill_id, sub_skill_name: sub_skill?.sub_skill_name, state: state, dispatch: dispatch}}>
            <CollapseButton
                key = {key}
            />
            
            
            <>
                <Collapse key={key} in={state.is_open_certificate_types === key} timeout="auto" unmountOnExit>
                    {state.is_loading ? <DivSpinner/> : <Html />}
                </Collapse>
            </>
            

            <AddCertificateType />
            <EditCertificateType />
        </Context.Provider>
    );
}

export default JobCertificateTypes;