import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { Stack, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { updateJobCertificateType } from '../service';
import { theme } from '../../../theme';
import BtnSpinner from '../../../components/btnSpinner';
import Context from '../context';
import ModalTemplateWithForm from '../../../components/modal/modalTemplateWithForm';

const EditCertificateType = () => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);

    return (
        <ModalTemplateWithForm
            show={context.state.is_show_edit_certificate_types} 
            closeFun = {() => context.dispatch({
                type: 'IS_SHOW_EDIT_CERTIFICATE_TYPES', 
                is_show_edit_certificate_types: false,
                selected_certificate_type: {}
            })}
            size = {null}
            centered = {false}
            fullscreen = {false}
            dialogClassName = {null}
            title = {`Edit ${context.state?.selected_job_certificate_type?.job_certificates_type_name}`}
            body = {
                <Container>
                    {context.state.error !== null ? (
                        <Stack sx={{ width: '100%' }} spacing={2} className="mb-3">
                            <Alert severity="error">{context.state.error}</Alert>
                        </Stack>
                    ) : null}

                    <label style={{ color: 'black' }}>CERTIFICATE TYPE</label>
                    <TextField
                        placeholder="Job certificate type name"
                        defaultValue={context.state?.selected_job_certificate_type?.job_certificates_type_name}
                        fullWidth
                        size="small"
                        name="job_certificates_type_name"
                        InputProps={{
                            style: { backgroundColor: '#fff' },
                        }}
                    />

                    <FormControlLabel 
                        control={
                            <Checkbox 
                                color={theme.main} 
                                name="job_certificates_type_is_mandatory"
                                defaultChecked = {context.state?.selected_job_certificate_type?.job_certificates_type_is_mandatory === 1 ? true : false}
                            />
                        } 
                        label="Mandatory" 
                        sx={{pointerEvents: "none", opacity: 0.5}}
                    />
                </Container>
            }
            footer = {
                <>
                    <Button
                        variant={theme.close_btn}
                        onClick={() => context.dispatch({
                            type: 'IS_SHOW_EDIT_CERTIFICATE_TYPES', 
                            is_show_edit_certificate_types: false
                        })}
                        size="sm"
                        disabled={context.state.btn_loading}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'CLOSE'}
                    </Button>
                    <Button
                        variant={theme.action_btn}
                        type="submit"
                        size="sm"
                        disabled={context.state.btn_loading}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'SAVE'}
                    </Button>
                </>
            }
            onSubmit = {(event) => updateJobCertificateType(event, context.state?.selected_job_certificate_type, context.dispatch, reset)}
        />
    );
};

export default EditCertificateType;