import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { Stack, TextField, Box } from '@mui/material';

import { saveNewSubSkill } from '../service';
import { theme } from '../../../theme';
import BtnSpinner from '../../../components/btnSpinner';
import Context from '../context';
import ModalTemplateWithForm from '../../../components/modal/modalTemplateWithForm';
import MuiIconPicker from '../../../components/muiIconPicker';

const AddSkills = () => {
    const {
        register,
        handleSubmit,
        reset,
        setValue
    } = useForm();

    const context = useContext(Context);
    const [selectedCustomerWebAppIcon, setSelectedCustomerWebAppIcon] = useState(null);
    const [selectedCustomerMobileAppIcon, setSelectedCustomerMobileAppIcon] = useState(null);

    const handleCustomerSkillIconSelect = (iconData) => {
        setSelectedCustomerWebAppIcon(iconData.name);
        setSelectedCustomerMobileAppIcon(iconData.code);
        setValue('skill_customer_web_app_icon', iconData.name); // Sync with form
        setValue('skill_customer_mobile_app_icon', iconData.code); // Sync with form
    };

    const resetForm = () => {
        reset();
        setSelectedCustomerWebAppIcon(null);
        setSelectedCustomerMobileAppIcon(null);
    }

    return (
        <ModalTemplateWithForm
            show={context.state.is_show_add_skills} 
            closeFun = {() => context.dispatch({type: 'IS_SHOW_ADD_SKILLS', is_show_add_skills: false})}
            size = {null}
            centered = {false}
            fullscreen = {false}
            dialogClassName = {null}
            title = {'Add a new service category'}
            body = {
                <Container>
                    {context.state.error !== null ? (
                        <Stack sx={{ width: '100%' }} spacing={2} className="mb-3">
                            <Alert severity="error">{context.state.error}</Alert>
                        </Stack>
                    ) : null}

                    <label style={{ color: 'black' }}>NAME</label>
                    <TextField
                        placeholder="Service category name"
                        fullWidth
                        size="small"
                        InputProps={{
                            style: { backgroundColor: '#fff' },
                        }}
                        {...register('skill_name', { required: false })}
                    />

                    <label style={{ color: 'black' }}>ADD ICONS FOR CUSTOMER APPS</label>
                    <MuiIconPicker onIconSelect={(iconData) => handleCustomerSkillIconSelect(iconData)} />

                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2} // Adds space between the TextFields
                    >
                        <TextField
                            placeholder="Web app icon"
                            fullWidth
                            size="small"
                            value={selectedCustomerWebAppIcon || ''}
                            InputProps={{
                                style: { backgroundColor: '#fff' },
                            }}
                            {...register('skill_customer_web_app_icon', { required: false })}
                        />

                        <TextField
                            placeholder="Mobile app icon"
                            fullWidth
                            size="small"
                            value={selectedCustomerMobileAppIcon || ''}
                            InputProps={{
                                style: { backgroundColor: '#fff' },
                            }}
                            {...register('skill_customer_mobile_app_icon', { required: false })}
                        />
                    </Box>
                </Container>
            }
            footer = {
                <>
                    <Button
                        variant={theme.close_btn}
                        onClick={() => context.dispatch({type: 'IS_SHOW_ADD_SKILLS', is_show_add_skills: false})}
                        size="sm"
                        disabled={context.state.btn_loading}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'CLOSE'}
                    </Button>
                    <Button
                        variant={theme.action_btn}
                        type="submit"
                        size="sm"
                        disabled={context.state.btn_loading}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'SAVE'}
                    </Button>
                </>
            }
            onSubmit = {handleSubmit((data) => saveNewSubSkill(data, context.dispatch, resetForm))}
        />
    );
};

export default AddSkills;